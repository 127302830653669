import React, { Component } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Lottie from "react-lottie";
import scrollToComponent from "react-scroll-to-component";
import config from "./config.js";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import BackToTop from "./BackToTop";
import star from "./assets/star.svg";

import * as Header from "./Home.Header";
import * as AboutMe from "./Home.AboutMe";
import * as Contact from "./Home.Contact";
import MainArea from "./Home.MainArea";
import linkedIn from "./assets/social/linkedin.svg";
import github from "./assets/social/github.svg";
import medium from "./assets/social/medium.svg";

//import './assets/vendor/hamburgers.min.css';

import InViewMonitor from "react-inview-monitor";
import { withRouter } from "react-router-dom";

import { Helmet } from "react-helmet";

let recaptchaInstance;

const resetRecaptcha = () => {
  recaptchaInstance.reset();
};

const animationStep = Header.animationStep;

const HomeGlobalStyle = createGlobalStyle`
  .Home {
    opacity:0;
    position:fixed;
    &.ready {
      opacity:1;
      position:initial;
      overflow-x: hidden;
    }
    &.fade-exit {
      opacity:1;
      transition:opacity 0.5s;
    }
    &.fade-exit-active {
      opacity:0;
    }
    &.fade-exit-done {
      opacity:0;
    }
  }
  .footer_text {
    bottom: -200px;
    right: 40px;
    z-index: 7;
    position: absolute;
    @media (max-width: 1100px) {
      right: 40px;
    }
    @media (max-width: 992px) {
    }
    width: 250px;
    color: #fff;
    font-size: 14px;
    padding-top: 20px;
    border-top: 2px solid #fff;
    @media (max-width: 600px) {
      font-size: 12px;
      width:150px;
    }
    p {
      display:inline-block;
      position:relative;
      cursor:pointer;
    }
    p:after {
      content:"";
      width:100%;
      height:2px;
      background:#fff;
      position:absolute;
      bottom:-7px;
      left:0;
      transform:scale(0,3);
      transform-origin:bottom left;
      transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    p:hover {
      &:after {
        width:100%;
        transform:scale(1,1);
      }
    }
  }
`;

const SocialArea = styled.div`
  .icons {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    .icon {
      position: relative;
      flex: 0 0 60px;
      &:not(:last-of-type) {
        margin-right: 24px;
      }
      a {
        width: 100%;
        display: block;
        text-decoration: none;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
        transform: scale(1);
        &:hover {
          transform: scale(1.2) rotate(15deg);
        }
      }
      img {
        width: 100%;
      }
    }
  }
  .iconWrapper {
    .icon {
      transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
      transform: scale(0);
      &:nth-child(2) {
        transition-delay: 0.4s;
      }
      &:nth-child(3) {
        transition-delay: 0.6s;
      }
      &:nth-child(4) {
        transition-delay: 0.8s;
      }
      &:nth-child(5) {
        transition-delay: 1s;
      }
      &:nth-child(6) {
        transition-delay: 1.2s;
      }
    }
    &.visible {
      .icon {
        transform: scale(1);
      }
    }
  }
  margin-bottom: 300px;
  @media (max-width: 992px) {
    margin-bottom: 250px;
  }
`;

class Home extends Component {
  constructor(props) {
    super(props);
    const texts = this.props.new_web_data.headerInfoTexts.map((e) => [
      e.textOne,
      e.textTwo,
    ]);
    const subtexts = this.props.new_web_data.headerInfoSubtexts.map((e) => [
      e.subtext,
    ]);
    const actionButtons = this.props.new_web_data.headerInfoButtons.map((e) => {
      return {
        link: e.buttonLink,
        text: e.buttonText,
      };
    });
    const educationData = this.props.new_web_data.educations.map((e) => {
      return {
        date: e.date,
        desc: e.desc,
        school: e.school,
        title: e.title,
      };
    });
    const experienceData = this.props.new_web_data.experiences.map((e) => {
      return {
        date: e.date,
        desc: e.desc,
        titleUrl: e.titleUrl,
        title: e.title,
      };
    });
    const webProjects = this.props.new_web_data.projects.map((e, i) => {
      const proj = e;
      return {
        id: i,
        color: proj.color,
        content: proj.content.map((pr) => {
          return {
            img: pr.img ? pr.img.url : undefined,
            poster: pr.poster ? pr.poster.url : undefined,
            content: pr.content,
            video: pr.video ? pr.video.url : undefined,
            priorityImg: pr.priorityImg ? pr.priorityImg.url : undefined,
            title: pr.title,
            type: pr["type"],
          };
        }),
        link: proj.link,
        subtitle: proj.subtitle,
        tags: proj.tags,
        thumb: proj.thumb.url,
        title: e.title,
        type: proj["type"],
        url: proj.url,
        video_url: proj.video_url,
        provider: proj.provider,
        gifVideo: proj.gif_video ? proj.gif_video.url : undefined,
        gif: proj.gif ? proj.gif.url : undefined,
        gifLoaded: false,
        mobile_thumb: proj.mobile_thumb ? proj.mobile_thumb.url : undefined,
        pc_thumb: proj.pc_thumb ? proj.pc_thumb.url : undefined,
      };
    });
    this.state = {
      animation: this.props.animations[0],
      currentAnimation: 1,
      animDuration: 5600,
      animProgress: 0,
      animStep: 56,
      animStarted: false,
      animEnding: false,
      isPaused: true,
      ready: false,
      boxesClickable: true,
      burgerMenuOpen: false,
      tick: "",
      mockup: {
        title: "",
        subtitle: "",
        posX: 0,
        posY: 0,
        tags: "",
        width: 500,
      },
      mobileMenuSize: 0,
      openingProject: false,
      texts,
      subtexts,
      actionButtons,
      webProjects,
      educationData,
      experienceData,
      aboutAnimation: false,
      aboutAnimation2: false,
      aboutWidth: 200,
      aboutHeight: 350,
      aboutRight: 0,
      aboutTimeout: "",
      readyFunc: "",

      mailAnimation: false,
      mailAnimation2: false,
      mailWidth: 200,
      mailHeight: 350,

      mailHandWidth: 235,
      mailHandHeight: 350,
      mailHandRight: 298,
      mailTimeout: "",

      formName: "",
      formEmail: "",
      formEmailValid: false,
      formMsg: "",
      formCaptcha: "",
      formSending: false,
      formSent: false,
      formSendError: false,
      headerFixed: false,
      nnav_open: false,
      gifs: [],
    };
    this.tick = this.tick.bind(this);
    this.changeAnimation = this.changeAnimation.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.loadAbout = this.loadAbout.bind(this);
    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.getCoords = this.getCoords.bind(this);
    this.onCaptchaChange = this.onCaptchaChange.bind(this);
    this.handleSendForm = this.handleSendForm.bind(this);
    this.loadVideoImage = this.loadVideoImage.bind(this);
  }
  handleSendForm() {
    let s = this.state;
    this.setState({ formSending: true, formSent: false });
    axios
      .post(
        config.be_server_root + "/sendMail",
        {
          name: s.formName,
          email: s.formEmail,
          msg: s.formMsg,
          captcha: s.formCaptcha,
        },
        { headers: { "Content-type": "application/json" } }
      )
      .then((response) => {
        if (response.data.success === true) {
          this.setState({
            formSending: false,
            formSent: true,
            formName: "",
            formEmail: "",
            formEmailValid: false,
            formMsg: "",
            formCaptcha: "",
            formSendError: false,
          });
          resetRecaptcha();
        } else {
          this.setState({ formSending: false, formSendError: true });
        }
      })
      .catch((error) => {
        //console.log('failed',error);
        this.setState({ formSending: false, formSendError: true });
      });
  }
  onCaptchaChange(value) {
    this.setState({ formCaptcha: value });
  }
  getCoords(elem) {
    // crossbrowser version
    let box = elem.getBoundingClientRect();

    let body = document.body;
    let docEl = document.documentElement;

    let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    let clientTop = docEl.clientTop || body.clientTop || 0;
    let clientLeft = docEl.clientLeft || body.clientLeft || 0;

    let top = box.top + scrollTop - clientTop;
    let left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }
  handleResize() {
    //console.log(window.innerWidth);
    this.setState({
      mobileMenuSize: document.getElementById("root").getBoundingClientRect()
        .width,
      aboutWidth: window.innerWidth <= 840 ? 114 : 200,
      aboutHeight: window.innerWidth <= 840 ? 200 : 350,
      aboutRight: window.innerWidth <= 840 ? 15 : 0,
      /* mailWidth: (window.innerWidth <= 840 ? 114 : 200),
      mailHeight: (window.innerWidth <= 840 ? 200 : 350),
      mailHandWidth: (window.innerWidth <= 840 ? 140 : 235),
      mailHandRight: (window.innerWidth <= 840 ? 303 : 298) */
    });
  }
  handleScroll(e) {
    let theHeight = 300;
    if (window.pageYOffset > theHeight && !this.state.headerFixed) {
      this.setState({ headerFixed: true });
    } else if (window.pageYOffset <= theHeight && this.state.headerFixed) {
      this.setState({ headerFixed: false });
    }
  }
  formChangeHandler(e) {
    let nam = e.target.getAttribute("name");
    let full_name = nam.charAt(0).toUpperCase() + nam.slice(1);
    if (full_name === "Email") {
      //console.log(this.validateEmail(e.target.value));
      this.setState({
        [`form${full_name}`]: e.target.value,
        formEmailValid: this.validateEmail(e.target.value),
      });
    } else {
      this.setState({ [`form${full_name}`]: e.target.value });
    }
  }
  validateEmail(email) {
    // eslint-disable-next-line
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  loadAbout() {
    if (!this.state.aboutAnimation) {
      fetch(
        this.props.new_web_data.animations.find((a) => a.title === "about").file
          .url
      ).then((response) => {
        response.json().then((json) => {
          fetch(
            this.props.new_web_data.animations.find(
              (a) => a.title === "about_2"
            ).file.url
          ).then((res) => {
            res.json().then((json2) => {
              this.setState({
                aboutTimeout: setTimeout(
                  () =>
                    this.setState({
                      aboutAnimation: json,
                      aboutAnimation2: json2,
                    }),
                  1500
                ),
              });
            });
          });
        });
      });
    } else {
      if (this.refs.lottie2.anim.currentFrame === 140) {
        this.refs.lottie2.anim.goToAndPlay(1);
        this.refs.lottie3.anim.goToAndPlay(1);
      }
    }
  }
  loadMail() {
    if (!this.state.mailAnimation) {
      fetch(
        this.props.new_web_data.animations.find(
          (a) => a.title === "mail_animation"
        ).file.url
      ).then((response) => {
        response.json().then((json) => {
          fetch(
            this.props.new_web_data.animations.find(
              (a) => a.title === "mail_animation_2"
            ).file.url
          ).then((res) => {
            res.json().then((json2) => {
              this.setState({
                mailTimeout: setTimeout(
                  () =>
                    this.setState({
                      mailAnimation: json,
                      mailAnimation2: json2,
                    }),
                  1500
                ),
              });
            });
          });
        });
      });
    } else {
      if (this.refs.lottie4.anim.currentFrame === 140) {
        this.refs.lottie4.anim.goToAndPlay(1);
        this.refs.lottie5.anim.goToAndPlay(1);
      }
    }
  }
  loadVideoImage(thumb, id) {
    fetch(thumb).then(() => {
      const gifProj = this.state.webProjects.find((e) => e.id === id);
      if (gifProj && !gifProj.gifLoaded) {
        let im = new Image();
        im.src = gifProj.gif;
        im.onload = () => {
          this.setState({
            webProjects: this.state.webProjects.map((p) => {
              if (p.id === id) {
                return { ...p, gifLoaded: true };
              }
              return p;
            }),
          });
        };
      }
    });
  }
  componentDidMount() {
    this._ismounted = true;
    this.props.justLoaded();
    //console.log('home mounted', this.state.readyFunc);
    this.setState({
      aboutWidth: window.innerWidth <= 840 ? 114 : 200,
      aboutHeight: window.innerWidth <= 840 ? 200 : 350,
      aboutRight: window.innerWidth <= 840 ? 15 : 0,
      /* mailWidth: (window.innerWidth <= 840 ? 114 : 200),
      mailHeight: (window.innerWidth <= 840 ? 200 : 350),
      mailHandWidth: (window.innerWidth <= 840 ? 140 : 235),
      mailHandRight: (window.innerWidth <= 840 ? -303 : -298), */
      openingProject: false,
      mobileMenuSize: document.getElementById("root").getBoundingClientRect()
        .width,
      animFunc: setTimeout(() => {
        if (this._ismounted) this.setState({ isPaused: false });
      }, 700),
      animStepFunc: setTimeout(() => {
        if (this._ismounted)
          this.setState({
            tick: setInterval(() => this.tick(), animationStep),
          });
      }, 700),
      readyFunc: setTimeout(() => {
        window.scrollTo(0, 0);
        if (this._ismounted) this.setState({ ready: true });
      }, 500),
      //tick: setInterval(() => this.tick(), animationStep)
    });
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    this._ismounted = false;
    //console.log('home will unmount');
    clearInterval(this.state.tick);
    clearTimeout(this.state.animStepFunc);
    //console.log('home will unmount',this.state.readyFunc);
    clearTimeout(this.state.readyFunc);
    //console.log('home will unmount',this.state.readyFunc);
    clearTimeout(this.state.animFunc);
    clearTimeout(this.state.aboutTimeout);
    clearTimeout(this.state.mailTimeout);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  }
  tick() {
    let { animProgress, animStep, animStarted, animEnding } = this.state;
    if (animProgress > 0 && !animStarted) {
      this.setState({
        animStarted: true,
        animProgress: animProgress + animationStep / animStep,
      });
    } else {
      if (animProgress > 95 && !animEnding) {
        this.setState({
          animProgress: animProgress + animationStep / animStep,
          animEnding: true,
        });
      } else {
        this.setState({
          animProgress: animProgress + animationStep / animStep,
        });
      }
    }
  }
  changeAnimation(nr) {
    if (nr === 1) {
      this.setState({
        currentAnimation: 2,
        animation: this.props.animations[1],
        animProgress: 0,
        animStep: 76,
        animStarted: false,
        animEnding: false,
      });
    } else if (nr === 2) {
      this.setState({
        currentAnimation: 3,
        animation: this.props.animations[2],
        animProgress: 0,
        animStep: 66,
        animStarted: false,
        animEnding: false,
      });
    } else if (nr === 3) {
      this.setState({
        currentAnimation: 1,
        animation: this.props.animations[0],
        animProgress: 0,
        animStep: 56,
        animStarted: false,
        animEnding: false,
      });
    }
    this.refs.lottie.anim.goToAndPlay(1);
  }
  render() {
    const wd = this.props.new_web_data;
    const headerTitle = wd.generalDataInfos[0].head_title;
    const headerSubTitle = wd.generalDataInfos[0].home_title;
    let defaultOptions1 = {
      autoplay: true,
      animationData: this.state.animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      loop: false,
    };
    let defaultOptions2 = {
      autoplay: true,
      animationData: this.state.aboutAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      loop: false,
    };
    let defaultOptions3 = {
      autoplay: true,
      animationData: this.state.aboutAnimation2,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      loop: false,
    };
    let defaultOptions4 = {
      autoplay: true,
      animationData: this.state.mailAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      loop: false,
    };
    let defaultOptions5 = {
      autoplay: true,
      animationData: this.state.mailAnimation2,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      loop: false,
    };
    let {
      currentAnimation,
      texts,
      actionButtons,
      webProjects,
      animStarted,
      animEnding,
      openingProject,
      ready,
      mobileMenuSize,
      boxesClickable,
      burgerMenuOpen,
      aboutAnimation,
      aboutAnimation2,
      mailAnimation,
      mailAnimation2,
      educationData,
      experienceData,

      formName,
      formEmail,
      formEmailValid,
      formMsg,
      formSending,
      formSent,
      formSendError,
      formCaptcha,
      headerFixed,
      subtexts,
      nnav_open,
    } = this.state;
    let menuItems = [
      {
        title: "projects",
        scroll: this.refs.project_area,
        width: 58,
      },
      {
        title: "about me",
        scroll: this.refs.about_area,
        width: 67,
      },
      {
        title: "contact",
        scroll: this.refs.contact_area,
        width: 57,
      },
      {
        title: "my blog",
        width: 57,
        url: "https://blog.gauracs.com",
      },
    ];
    let menuItemsArray = (
      <ul>
        {menuItems.map((e, i) => {
          return (
            <li
              key={i}
              onClick={() => {
                if (e.scroll) {
                  let offset = -70;
                  scrollToComponent(e.scroll, {
                    offset,
                    align: "top",
                    duration: 1000,
                    ease: "inOutExpo",
                  });
                  if (burgerMenuOpen) {
                    this.setState({ burgerMenuOpen: false });
                    document.body.classList.remove("mobile-menu-open");
                  }
                } else {
                  window.location.href = e.url;
                }
              }}
            >
              {e.title === "my blog" && (
                <img src={star} alt="star" className="floating_star" />
              )}
              {e.title}
            </li>
          );
        })}
      </ul>
    );
    return (
      <React.Fragment>
        <HomeGlobalStyle />

        <div style={{ position: "relative" }}>
          {ready && (
            <Helmet
              title={headerTitle + headerSubTitle}
              meta={[
                { name: "description", content: subtexts[0] },
                { property: "og:type", content: "website" },
                { property: "og:title", content: headerTitle + headerSubTitle },
                { property: "og:url", content: "https://andris.gauracs.com" },
              ]}
            />
          )}
          <div
            className={"Home " + (ready ? "ready" : "not_ready")}
            ref="app"
            id="app_front"
          >
            <Header.MenuButton
              className={`${burgerMenuOpen ? "is-active" : ""}`}
            >
              <div
                onClick={() => {
                  this.setState({ burgerMenuOpen: !burgerMenuOpen });
                  if (!burgerMenuOpen) {
                    document.body.classList.add("mobile-menu-open");
                  } else {
                    document.body.classList.remove("mobile-menu-open");
                  }
                }}
                className={`hamburger hamburger--elastic ${
                  burgerMenuOpen ? "is-active" : ""
                }`}
                tabIndex="0"
                aria-label="Menu"
                role="button"
                aria-controls="navigation"
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner" />
                </div>
              </div>
            </Header.MenuButton>
            <Header.MobileMenu
              thewidth={mobileMenuSize}
              className={`${burgerMenuOpen ? "is-active" : ""}`}
            >
              <nav>
                <ul>{menuItemsArray}</ul>
              </nav>
            </Header.MobileMenu>
            <Header.Triangle className="upper_triangle" />
            <Header.AppCore
              className={"app_core " + (openingProject ? "opening" : "")}
            >
              <header className={`App-header`}>
                <div className="inner">
                  <nav>
                    <ul>{menuItemsArray}</ul>
                  </nav>
                  <div className="App-logo">
                    <svg
                      width="117"
                      height="103"
                      viewBox="0 0 117 103"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M72.592 99l-.432-9.504c-2.304 4.032-6 7.176-11.088 9.432-5.088 2.256-10.944 3.384-17.568 3.384-6.624 0-12.6-1.416-17.928-4.248-5.328-2.832-9.888-6.624-13.68-11.376S5.2 76.464 3.184 70.272.16 57.672.16 51.048c0-7.008 1.008-13.608 3.024-19.8s4.992-11.592 8.928-16.2C16.048 10.44 20.944 6.816 26.8 4.176c5.856-2.64 12.672-3.96 20.448-3.96 5.664 0 10.968.816 15.912 2.448 4.944 1.632 9.264 3.888 12.96 6.768 3.696 2.88 6.672 6.312 8.928 10.296 2.256 3.984 3.576 8.376 3.96 13.176h-20.16c-.288-2.208-1.056-4.296-2.304-6.264a19.224 19.224 0 0 0-4.752-5.112c-1.92-1.44-4.152-2.592-6.696-3.456a24.242 24.242 0 0 0-7.848-1.296c-8.064 0-14.52 3-19.368 9-4.848 6-7.272 14.712-7.272 26.136 0 3.936.504 7.896 1.512 11.88 1.008 3.984 2.592 7.584 4.752 10.8 2.16 3.216 4.92 5.832 8.28 7.848 3.36 2.016 7.344 3.024 11.952 3.024 3.264 0 6.312-.576 9.144-1.728 2.832-1.152 5.304-2.736 7.416-4.752A22.369 22.369 0 0 0 68.632 72c1.2-2.64 1.8-5.448 1.8-8.424H49.264V47.592h39.888V99h-16.56zm23.616 0V79.56h20.016V99H96.208z"
                        fill={`#63B2FF`}
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="mobile_menu_toggle" />
                </div>
              </header>
              <BackToTop visible={headerFixed} />
              <Header.CircleNav
                className={`top-right ${nnav_open ? "open" : ""} ${
                  headerFixed ? "fixed" : ""
                }`}
              >
                <div
                  className={`disc l1`}
                  onClick={() => {
                    scrollToComponent(document.documentElement, {
                      offset: 0,
                      align: "top",
                      duration: 1000,
                      ease: "inOutExpo",
                    });
                    if (nnav_open) this.setState({ nnav_open: false });
                  }}
                >
                  <span style={{ width: `41px` }} className="title">
                    {"home"}
                  </span>
                </div>
                {menuItems.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className={`disc l${i + 2}`}
                      onClick={() => {
                        if (e.scroll) {
                          let offset = -70;
                          scrollToComponent(e.scroll, {
                            offset,
                            align: "top",
                            duration: 1000,
                            ease: "inOutExpo",
                          });
                        } else {
                          window.location.href = e.url;
                        }
                        if (nnav_open) this.setState({ nnav_open: false });
                      }}
                    >
                      <span style={{ width: `${e.width}px` }} className="title">
                        {e.title}
                      </span>
                    </div>
                  );
                })}
                <div
                  className={`menu_toggle toggle`}
                  onClick={() => {
                    this.setState({ nnav_open: !nnav_open });
                  }}
                >
                  <div
                    className={`hamburger hamburger--elastic ${
                      nnav_open ? "is-active" : ""
                    }`}
                    tabIndex="0"
                    aria-label="Menu"
                    role="button"
                    aria-controls="navigation"
                  >
                    <div className="hamburger-box">
                      <div className="hamburger-inner" />
                    </div>
                  </div>
                </div>
              </Header.CircleNav>
              <Header.LottieWrapper>
                <Lottie
                  ref={"lottie"}
                  options={defaultOptions1}
                  width={700}
                  height={700}
                  isPaused={this.state.isPaused}
                  eventListeners={[
                    {
                      eventName: "complete",
                      callback: () => this.changeAnimation(currentAnimation),
                    },
                  ]}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                />
                <Header.Platform />
              </Header.LottieWrapper>
              <Header.Bullets>
                <ul>
                  <li
                    onClick={() => this.changeAnimation(3)}
                    className={currentAnimation === 1 ? "active" : ""}
                  />
                  <li
                    onClick={() => this.changeAnimation(1)}
                    className={currentAnimation === 2 ? "active" : ""}
                  />
                  <li
                    onClick={() => this.changeAnimation(2)}
                    className={currentAnimation === 3 ? "active" : ""}
                  />
                </ul>
              </Header.Bullets>
              <Header.HeaderBox key={currentAnimation}>
                <h2
                  className={
                    (animStarted ? "active" : "") +
                    " " +
                    (animEnding ? "ending" : "")
                  }
                >
                  <div className="smaller">
                    {texts[currentAnimation - 1][0]}
                  </div>
                  <div className="bigger">{texts[currentAnimation - 1][1]}</div>
                </h2>
                <div className="slider">
                  <div
                    className="progress"
                    style={{ width: this.state.animProgress + "%" }}
                  />
                </div>
                <div
                  className={
                    "desc " +
                    (animStarted ? "active" : "") +
                    " " +
                    (animEnding ? "ending" : "")
                  }
                >
                  <div className="main_text">
                    {subtexts[currentAnimation - 1]}
                  </div>
                  <div
                    className="cta"
                    onClick={() => {
                      let linkk = actionButtons[currentAnimation - 1].link;
                      let scrolllTarget =
                        linkk === "web"
                          ? this.refs.web_project_area
                          : linkk === "video"
                          ? this.refs.video_project_area
                          : this.refs.contact_area;
                      scrollToComponent(scrolllTarget, {
                        offset: -70,
                        align: "top",
                        duration: 1000,
                        ease: "inOutExpo",
                      });
                    }}
                  >
                    {actionButtons[currentAnimation - 1].text}
                  </div>
                </div>
              </Header.HeaderBox>

              <MainArea boxtitlewidth={this.props.boxtitlewidth}>
                <div className="projects" ref={"project_area"}>
                  <div className="titleWrapper">
                    <InViewMonitor
                      classNameInView="line-1 active"
                      classNameNotInView="line-1"
                      intoViewMargin="0px"
                      toggleClassNameOnInView={false}
                    >
                      <h3 className="anim-typewriter">
                        {"projects"}
                        <span className="blue">{"."}</span>
                      </h3>
                      <div className="blue_line" />
                    </InViewMonitor>
                  </div>
                  <InViewMonitor
                    ref={"web_project_area"}
                    classNameInView="web-development visible"
                    classNameNotInView="web-development"
                    intoViewMargin="-50px"
                    toggleClassNameOnInView={false}
                  >
                    <h4>
                      {"Web design & Development"}
                      <span className="blue">.</span>
                    </h4>
                    <div className="blue_line smaller" />
                  </InViewMonitor>
                  <div className="items">
                    {this.props.new_web_data &&
                      webProjects.map(
                        (e, i) =>
                          e.type === "web" && (
                            <InViewMonitor
                              classNameInView="project_box visible"
                              classNameNotInView="project_box"
                              intoViewMargin="-150px"
                              key={i}
                              toggleClassNameOnInView={false}
                            >
                              <div
                                onClick={() => {
                                  if (boxesClickable) {
                                    let theRef = document.getElementById(
                                      `project_${i}`
                                    );
                                    let w = theRef.offsetWidth;
                                    let titlesize =
                                      theRef.getBoundingClientRect().height;
                                    this.props.history.push({
                                      pathname: `/${e.link}`,
                                      state: { width: w, titlesize: titlesize },
                                    });
                                    this.props.openProject(
                                      e.title,
                                      e.subtitle,
                                      e.tags,
                                      i,
                                      e.link
                                    );
                                    this.setState({ boxesClickable: false });
                                  }
                                }}
                              >
                                {/* <div className='project_box'> */}
                                <div
                                  className="img_wrap"
                                  style={{
                                    backgroundImage: `url(${e.thumb})`,
                                  }}
                                />
                                <div>
                                  {(!openingProject ||
                                    this.props.backFromProjects) && (
                                    <div
                                      className={"desc_box"}
                                      id={`project_${i}`}
                                    >
                                      <div className="title">
                                        {e.title}
                                        <span className="blue">.</span>
                                      </div>
                                      <div className="subtitle">
                                        {e.subtitle}
                                      </div>
                                      <div className="tags">{e.tags}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* </div> */}
                            </InViewMonitor>
                          )
                      )}
                  </div>
                  <InViewMonitor
                    ref={"video_project_area"}
                    classNameInView="web-development visible"
                    classNameNotInView="web-development"
                    intoViewMargin="-50px"
                    toggleClassNameOnInView={false}
                  >
                    <h4>
                      {"Video work & Motion graphics"}
                      <span className="blue">.</span>
                    </h4>
                    <div className="blue_line smaller" />
                  </InViewMonitor>
                  <div className="items">
                    {this.props.new_web_data &&
                      webProjects.map(
                        (e, i) =>
                          e.type === "video" && (
                            <InViewMonitor
                              classNameInView="project_box visible"
                              classNameNotInView="project_box"
                              intoViewMargin="-150px"
                              key={i}
                              toggleClassNameOnInView={false}
                              onInView={() => {
                                this.loadVideoImage(e.thumb, e.id);
                              }}
                            >
                              <div
                                onClick={() => {
                                  if (boxesClickable) {
                                    let theRef = document.getElementById(
                                      `project_${i}`
                                    );
                                    let w = theRef.offsetWidth;
                                    let titlesize =
                                      theRef.getBoundingClientRect().height;
                                    this.props.history.push({
                                      pathname: `/${e.link}`,
                                      state: { width: w, titlesize: titlesize },
                                    });
                                    this.props.openProject(
                                      e.title,
                                      e.subtitle,
                                      e.tags,
                                      i,
                                      e.link
                                    );
                                    this.setState({ boxesClickable: false });
                                  }
                                }}
                              >
                                {/* <div className='project_box'> */}
                                {this.props.isMobile ? (
                                  <div
                                    className="img_wrap"
                                    style={{
                                      backgroundImage: `url(${e.thumb})`,
                                    }}
                                  >
                                    <Contact.FormLoader type="gif">
                                      <div />
                                      <div />
                                      <div />
                                      <div />
                                    </Contact.FormLoader>
                                    {e.gifLoaded && (
                                      <div
                                        className="the_gif"
                                        style={{
                                          backgroundImage: `url(${e.gif})`,
                                        }}
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <div className="img_wrap">
                                    <video
                                      autoPlay
                                      loop
                                      muted
                                      preload="true"
                                      poster={e.thumb}
                                    >
                                      <source
                                        src={e.gifVideo}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                )}
                                <div>
                                  {(!openingProject ||
                                    this.props.backFromProjects) && (
                                    <div
                                      className={"desc_box"}
                                      id={`project_${i}`}
                                    >
                                      <div className="title">
                                        {e.title}
                                        <span className="blue">.</span>
                                      </div>
                                      <div className="subtitle">
                                        {e.subtitle}
                                      </div>
                                      <div className="tags">{e.tags}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* </div> */}
                            </InViewMonitor>
                          )
                      )}
                  </div>
                  <InViewMonitor
                    classNameInView="web-development visible"
                    classNameNotInView="web-development"
                    intoViewMargin="-50px"
                    toggleClassNameOnInView={false}
                  >
                    <h4>
                      {"Other projects"}
                      <span className="blue">.</span>
                    </h4>
                    <div className="blue_line smaller" />
                  </InViewMonitor>
                  <div className="items">
                    {this.props.new_web_data &&
                      webProjects.map(
                        (e, i) =>
                          e.type === "other" && (
                            <InViewMonitor
                              classNameInView="project_box visible"
                              classNameNotInView="project_box"
                              intoViewMargin="-150px"
                              key={i}
                              toggleClassNameOnInView={false}
                              onInView={() => {
                                this.loadVideoImage(e.thumb, e.id);
                              }}
                            >
                              <div
                                onClick={() => {
                                  if (boxesClickable) {
                                    let theRef = document.getElementById(
                                      `project_${i}`
                                    );
                                    let w = theRef.offsetWidth;
                                    let titlesize =
                                      theRef.getBoundingClientRect().height;
                                    this.props.history.push({
                                      pathname: `/${e.link}`,
                                      state: { width: w, titlesize: titlesize },
                                    });
                                    this.props.openProject(
                                      e.title,
                                      e.subtitle,
                                      e.tags,
                                      i,
                                      e.link
                                    );
                                    this.setState({ boxesClickable: false });
                                  }
                                }}
                              >
                                {/* <div className='project_box'> */}
                                {this.props.isMobile ? (
                                  <div
                                    className="img_wrap"
                                    style={{
                                      backgroundImage: `url(${e.thumb})`,
                                    }}
                                  >
                                    <Contact.FormLoader type="gif">
                                      <div />
                                      <div />
                                      <div />
                                      <div />
                                    </Contact.FormLoader>
                                    {e.gifLoaded && (
                                      <div
                                        className="the_gif"
                                        style={{
                                          backgroundImage: `url(${e.gif})`,
                                        }}
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <div className="img_wrap">
                                    <video
                                      autoPlay
                                      loop
                                      muted
                                      preload="true"
                                      poster={e.thumb}
                                    >
                                      <source
                                        src={e.gifVideo}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                )}
                                <div>
                                  {(!openingProject ||
                                    this.props.backFromProjects) && (
                                    <div
                                      className={"desc_box"}
                                      id={`project_${i}`}
                                    >
                                      <div className="title">
                                        {e.title}
                                        <span className="blue">.</span>
                                      </div>
                                      <div className="subtitle">
                                        {e.subtitle}
                                      </div>
                                      <div className="tags">{e.tags}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* </div> */}
                            </InViewMonitor>
                          )
                      )}
                  </div>
                </div>
                <div ref={"about_area"}>
                  <AboutMe.AboutMe>
                    <div className="titleWrapper">
                      <InViewMonitor
                        classNameInView="line-1 active"
                        classNameNotInView="line-1"
                        intoViewMargin="0px"
                        toggleClassNameOnInView={false}
                      >
                        <h3 className="anim-typewriter">
                          {"about me"}
                          <span className="blue">{"."}</span>
                        </h3>
                        <div className="blue_line" />
                      </InViewMonitor>
                    </div>
                    <AboutMe.AboutMeArea>
                      <div className="experience">
                        <InViewMonitor
                          classNameInView="subtitle visible"
                          classNameNotInView="subtitle"
                          intoViewMargin="-50px"
                          toggleClassNameOnInView={false}
                        >
                          <h4>experience</h4>
                        </InViewMonitor>
                        <InViewMonitor
                          classNameInView="bullet_area visible"
                          classNameNotInView="bullet_area"
                          intoViewMargin="200px"
                          toggleClassNameOnInView={false}
                        >
                          <InViewMonitor
                            repeatOnInView={true}
                            onInView={() => {
                              this.loadAbout();
                            }}
                          >
                            <div className="character">
                              {aboutAnimation && (
                                <Lottie
                                  ref={"lottie2"}
                                  options={defaultOptions2}
                                  width={400}
                                  height={400}
                                  style={{
                                    width: `${this.state.aboutWidth}px`,
                                    height: `${this.state.aboutHeight}px`,
                                    position: "absolute",
                                    top: "0",
                                    right: `0`,
                                    pointerEvents: "none",
                                  }}
                                />
                              )}
                              {aboutAnimation2 && (
                                <div
                                  className="hand"
                                  style={{
                                    position: "absolute",
                                    right: "-37px",
                                  }}
                                >
                                  <Lottie
                                    ref={"lottie3"}
                                    options={defaultOptions3}
                                    width={400}
                                    height={400}
                                    style={{
                                      width: `${this.state.aboutWidth}px`,
                                      height: `${this.state.aboutHeight}px`,
                                      position: "absolute",
                                      top: "0",
                                      right: `${this.state.aboutRight}px`,
                                      pointerEvents: "none",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </InViewMonitor>
                          <ul>
                            {this.props.new_web_data &&
                              experienceData.map((e, i) => (
                                <li key={i}>
                                  <div className="title">{e.title}</div>
                                  <div className="date">{e.date}</div>
                                  <div className="desc">{e.desc}</div>
                                </li>
                              ))}
                          </ul>
                        </InViewMonitor>
                      </div>
                      <div className="education">
                        <InViewMonitor
                          classNameInView="subtitle visible"
                          classNameNotInView="subtitle"
                          intoViewMargin="-50px"
                          toggleClassNameOnInView={false}
                        >
                          <h4>education</h4>
                        </InViewMonitor>
                        <InViewMonitor
                          classNameInView="bullet_area visible"
                          classNameNotInView="bullet_area"
                          intoViewMargin="-50px"
                          toggleClassNameOnInView={false}
                        >
                          <ul>
                            {this.props.new_web_data &&
                              educationData.map((e, i) => (
                                <li key={i}>
                                  <div className="title">{e.title}</div>
                                  <div className="school">{e.school}</div>
                                  <div className="date">{e.date}</div>
                                  <div className="desc">{e.desc}</div>
                                </li>
                              ))}
                          </ul>
                        </InViewMonitor>
                      </div>
                    </AboutMe.AboutMeArea>
                  </AboutMe.AboutMe>
                </div>
                <div ref={"contact_area"}>
                  <Contact.Contact>
                    <div className="titleWrapper">
                      <InViewMonitor
                        classNameInView="line-1 active"
                        classNameNotInView="line-1"
                        intoViewMargin="0px"
                        toggleClassNameOnInView={false}
                      >
                        <h3 className="anim-typewriter">
                          {"contact"}
                          <span className="blue">{"."}</span>
                        </h3>
                        <div className="blue_line" />
                      </InViewMonitor>
                    </div>
                  </Contact.Contact>
                  <Contact.ContactArea>
                    <InViewMonitor
                      repeatOnInView={true}
                      classNameInView="visible"
                      intoViewMargin="200px"
                      onInView={() => {
                        this.loadMail();
                      }}
                    >
                      <InViewMonitor
                        classNameInView="subtitle visible"
                        classNameNotInView="subtitle"
                        intoViewMargin="-50px"
                        toggleClassNameOnInView={false}
                      >
                        <h3>
                          {"Send me a message"}
                          <span className="blue">{"!"}</span>
                        </h3>
                      </InViewMonitor>
                      <Contact.TheForm>
                        <InViewMonitor
                          classNameInView="formWrapper visible"
                          classNameNotInView="formWrapper"
                          intoViewMargin="-50px"
                          toggleClassNameOnInView={false}
                        >
                          <form>
                            <div className="input-group">
                              <input
                                type="text"
                                name="name"
                                className={
                                  formName.length > 0 ? "not_empty" : ""
                                }
                                value={formName}
                                onChange={this.formChangeHandler}
                              />
                              <label>{"name"}</label>
                              <div className="line" />
                            </div>
                            <div className="input-group">
                              <input
                                type="email"
                                name="email"
                                className={
                                  formEmail.length > 0 ? "not_empty" : ""
                                }
                                value={formEmail}
                                onChange={this.formChangeHandler}
                              />
                              <label>{"email"}</label>
                              {formEmail.length > 0 && !formEmailValid && (
                                <div className="not_valid">
                                  {"Email not valid"}
                                </div>
                              )}
                              <div className="line" />
                            </div>
                            <div className="input-group last">
                              <textarea
                                style={{ resize: "none" }}
                                name="msg"
                                className={
                                  formMsg.length > 0 ? "not_empty" : ""
                                }
                                value={formMsg}
                                onChange={this.formChangeHandler}
                              />
                              <label>{"message"}</label>
                              <div className="line" />
                            </div>
                            <div className="bottom_area">
                              <ReCAPTCHA
                                className={`recaptcha ${
                                  formName.length > 0 &&
                                  formEmailValid &&
                                  formMsg.length > 0
                                    ? "visible"
                                    : ""
                                }`}
                                sitekey="6Ldyl38UAAAAAN5IOJuSKNYxhy3jswugzlMuLz27"
                                onChange={this.onCaptchaChange}
                                ref={(e) => (recaptchaInstance = e)}
                              />
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleSendForm();
                                }}
                                disabled={
                                  !(
                                    formName.length > 0 &&
                                    formEmailValid &&
                                    formMsg.length > 0 &&
                                    formCaptcha &&
                                    formCaptcha.length > 0
                                  ) || formSending
                                }
                                type="button"
                              >
                                {!formSending ? (
                                  "send"
                                ) : (
                                  <Contact.FormLoader>
                                    <div />
                                    <div />
                                    <div />
                                    <div />
                                  </Contact.FormLoader>
                                )}
                              </button>
                            </div>
                            <div
                              className={`thankyou ${
                                formSent &&
                                !(
                                  formName.length > 0 ||
                                  formMsg.length > 0 ||
                                  formEmail.length > 0
                                )
                                  ? "visible"
                                  : ""
                              }`}
                            >
                              <div className="first_line">
                                {"The message has been sent"}
                                <span className="sign">{"!"}</span>
                              </div>
                              <div className="second_line">
                                {"Thank you"}
                                <span className="sign">{"!"}</span>
                              </div>
                            </div>
                            <div
                              className={`error ${
                                formSendError && !formSending && !formSent
                                  ? "visible"
                                  : ""
                              }`}
                            >
                              <div className="first_line">
                                {"Something went wrong... Please try again."}
                              </div>
                            </div>
                          </form>
                          <div className="character">
                            {mailAnimation && (
                              <Lottie
                                ref={"lottie4"}
                                options={defaultOptions4}
                                width={400}
                                height={400}
                                style={{
                                  width: `${this.state.mailWidth}px`,
                                  height: `${this.state.mailHeight}px`,
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            {mailAnimation2 && (
                              <div
                                className="hand"
                                style={{
                                  position: "absolute",
                                  right: "40px",
                                  zIndex: 4,
                                }}
                              >
                                <Lottie
                                  ref={"lottie5"}
                                  options={defaultOptions5}
                                  width={400}
                                  height={400}
                                  style={{
                                    width: `${this.state.mailHandWidth}px`,
                                    height: `${this.state.mailHeight}px`,
                                    position: "absolute",
                                    top: "0",
                                    left: `0`,
                                    pointerEvents: "none",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </InViewMonitor>
                      </Contact.TheForm>
                    </InViewMonitor>
                    <SocialArea>
                      <InViewMonitor
                        classNameInView="subtitle visible"
                        classNameNotInView="subtitle"
                        intoViewMargin="-50px"
                        toggleClassNameOnInView={false}
                      >
                        <h3>
                          {"Social"}
                          <span className="blue">{"."}</span>
                        </h3>
                      </InViewMonitor>
                      <InViewMonitor
                        classNameInView="iconWrapper visible"
                        classNameNotInView="iconWrapper"
                        intoViewMargin="-50px"
                        toggleClassNameOnInView={false}
                      >
                        <ul className="icons">
                          <li className="icon">
                            <a href="https://www.linkedin.com/in/andrisgauracs">
                              <img alt="linkedin" src={linkedIn} />
                            </a>
                          </li>
                          <li className="icon">
                            <a href="https://github.com/andrisgauracs">
                              <img alt="github" src={github} />
                            </a>
                          </li>
                          <li className="icon">
                            <a href="https://medium.com/@andrisgauracs">
                              <img alt="medium" src={medium} />
                            </a>
                          </li>
                        </ul>
                      </InViewMonitor>
                    </SocialArea>
                    <div
                      className="App-logo"
                      onClick={() => {
                        scrollToComponent(document.documentElement, {
                          offset: 0,
                          align: "top",
                          duration: 1000,
                          ease: "inOutExpo",
                        });
                      }}
                    >
                      <svg
                        width="117"
                        height="103"
                        viewBox="0 0 117 103"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M72.592 99l-.432-9.504c-2.304 4.032-6 7.176-11.088 9.432-5.088 2.256-10.944 3.384-17.568 3.384-6.624 0-12.6-1.416-17.928-4.248-5.328-2.832-9.888-6.624-13.68-11.376S5.2 76.464 3.184 70.272.16 57.672.16 51.048c0-7.008 1.008-13.608 3.024-19.8s4.992-11.592 8.928-16.2C16.048 10.44 20.944 6.816 26.8 4.176c5.856-2.64 12.672-3.96 20.448-3.96 5.664 0 10.968.816 15.912 2.448 4.944 1.632 9.264 3.888 12.96 6.768 3.696 2.88 6.672 6.312 8.928 10.296 2.256 3.984 3.576 8.376 3.96 13.176h-20.16c-.288-2.208-1.056-4.296-2.304-6.264a19.224 19.224 0 0 0-4.752-5.112c-1.92-1.44-4.152-2.592-6.696-3.456a24.242 24.242 0 0 0-7.848-1.296c-8.064 0-14.52 3-19.368 9-4.848 6-7.272 14.712-7.272 26.136 0 3.936.504 7.896 1.512 11.88 1.008 3.984 2.592 7.584 4.752 10.8 2.16 3.216 4.92 5.832 8.28 7.848 3.36 2.016 7.344 3.024 11.952 3.024 3.264 0 6.312-.576 9.144-1.728 2.832-1.152 5.304-2.736 7.416-4.752A22.369 22.369 0 0 0 68.632 72c1.2-2.64 1.8-5.448 1.8-8.424H49.264V47.592h39.888V99h-16.56zm23.616 0V79.56h20.016V99H96.208z"
                          fill={`#FFF`}
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="footer_text">
                      {"Website design, animation and code by "}
                      <p
                        onClick={() => {
                          scrollToComponent(this.refs.contact_area, {
                            offset: -70,
                            align: "top",
                            duration: 1000,
                            ease: "inOutExpo",
                          });
                        }}
                        style={{ fontSize: "18px", margin: 0 }}
                      >
                        {"Andris Gauracs"}
                      </p>
                    </div>
                  </Contact.ContactArea>
                </div>
              </MainArea>
            </Header.AppCore>
          </div>
          <Header.Triangle className="lower_triangle" />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
