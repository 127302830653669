import React from 'react';
import scrollToComponent from 'react-scroll-to-component';

const BackToTop = ({visible}) => (
  <div className={`centered-container totop ${(visible ? 'active' : '')}`} onClick={()=>scrollToComponent(document.documentElement, { offset: 0, align: 'top', duration: 1000, ease:'inOutExpo'})}>
    <div className="link link--arrowed">
      <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 32 32">
        <g fill="none" stroke="#63b1ff" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10">
          <circle className="arrow-icon--circle" cx="16" cy="16" r="15.12"></circle>
          <path className="arrow-icon--arrow" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"></path>
        </g>
      </svg>
      <span className="back">{'Back'}<span className="totop_text">{' to top'}</span><span className="question_mark">{'?'}</span></span>
    </div>
  </div>
)

export default BackToTop;
