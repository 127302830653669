import styled, { keyframes } from 'styled-components';

const typewriter2 = keyframes`
  from {width: 0;}
  to {width: 3.65em;}
`;

const blinkTextCursor = keyframes`
  from{background:rgba(99,178,255,.75);}
  to{background:transparent;}
`;

export const ContactArea = styled.div`
  h3 {
    font-size:36px;
    font-weight:normal;
  }
  .subtitle {
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
    transform:scale(1.4);
    opacity:0;
    transform-origin: left;
    &.visible {
      transform:scale(1); opacity:1;
    }
  }
  .App-logo {
    right:40px;
    position:absolute;
    bottom:-100px;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
    transform:scale(1);
    cursor:pointer;
    &:hover {
      transform:scale(1.2);
    }
    svg {
      width:100%;
      height:100%;
    }
    @media (max-width:540px) {
      bottom:-100px;
      right:40px;
      width:60px;
      height:60px;
    }
    /* @media (max-width:550px) {
      bottom:-120px;
      width:40px;
      height:40px;
    } */
  }
`;

export const Contact = styled.div`
  margin-top:200px;
  .blue_line {
    content:"";
    width:102px;
    height:5px;
    background:#63B2FF;
    display:block;
    margin-top:40px;
    @media (max-width:600px) {
      margin-bottom:60px;
    }
    &.smaller {
      width:50px;
      margin-bottom:65px;
      margin-top:10px;
    }
  }
  .titleWrapper {
    position:relative;
    overflow:hidden;
    height:180px;
  }
  .line-1 {
    height:87px;
  }
  .line-1 h3 {
    text-align: center;
    font-size:72px;
    @media (max-width:600px) {
      font-size:50px;
    }
    font-weight: 200;
    opacity:0;
    position:relative;
  }
  h4 {
    text-align: center;
    font-size:36px;
    font-weight: 200;
    text-align: left;
    margin-bottom:30px;
  }
  .line-1.active h3 {
    opacity:1;
    top: 50%;
    width: 3.7em;
    margin: 0;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
    position:relative;
    animation: ${typewriter2} 0.7s steps(10) 0s 1 normal both;
    &:after {
      content:"";
      display:block;
      position:absolute;
      right:0px;
      top:0;
      bottom:0;
      margin:auto;
      width:4px;
      height:52px;
      background:rgba(99,178,255,.75);
      animation: ${blinkTextCursor} 500ms steps(9) infinite normal;
    }
  }
`;

export const TheForm = styled.div`
  max-width:673px;
  width:100%;
  position:relative;
  margin-bottom:90px;
  @media (max-width:992px) {
    max-width:600px;
  }
  @media (max-width:840px) {
    max-width:480px;
  }
  @media (max-width:680px) {
    max-width:320px;
  }
  @media (max-width:540px) {
    max-width:65%;
  }
  .character {
    right:-80px;
    top:0;
    position:absolute;
    @media (max-width:540px) {
      right:-40px;
    }
  }
  .thankyou {
    display:block;
    height:0px;
    overflow:hidden;
    opacity:0;
    transition:height 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    text-align: center;
    width: 100%;
    margin-top: 20px;
    color: #424242;
    .first_line {
      font-size:20px;
      transform:scale(0);
      transition:transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
    }
    .second_line {
      font-size:40px;
      transform:scale(0);
      transition:transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.6s;
    }
    @media (max-width:540px) {
      .first_line {
        font-size:18px;
      }
      .second_line {
        font-size:20px;
      }
    }
    &.visible {
      position:initial;
      opacity:1;
      height:80px;
      .first_line, .second_line {
        transform:scale(1);
      }
    }
    .sign {
      color:#63B2FF;
    }
  }
  .error {
    display:none;
    color: #ff6060;
    text-align: right;
    width: 100%;
    &.visible {
      display:block;
    }
  }
  form {
    width:100%;
    margin:40px 40px 40px 0;
    padding:40px;
    @media (max-width:540px) {
      padding:20px;
    }
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.05);
    display:flex;
    flex-wrap: wrap;
    position:relative;
    z-index:2;
    .input-group {
      flex:0 1 50%;
      @media (max-width:1060px) {
        flex:0 1 100%;
      }
      box-sizing:border-box;
      &:first-of-type { padding-right:20px; }
      @media (max-width:1060px) {
        &:first-of-type { padding-right:0px; }
      }
      margin-top:20px;
      @media (max-width:540px) {
        &:first-of-type { margin-top:0px; }
      }
      &.last {
        padding-right:0px;
        flex: 0 0 100%;
      }
      position:relative;
      input, textarea {
        width:100%;
        color:#424242;
        font-weight:bold;
        font-size:24px;
        border:none;
        border-bottom:2px solid rgba(99,178,255,0.34);
        margin-bottom:25px;
        outline:0;
      }
      label {
        color:#5FADF9;
        font-weight:bold;
        letter-spacing:1px;
        font-size:24px;
        position:absolute;
        left:0;
        top:0;
        bottom:0;
        margin:auto;
        pointer-events: none;
        transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
        transform-origin: left;
        transform: translateY(5px);
      }
      input.not_empty ~ label, input:focus ~ label {
        transform: translateY(-26px) scale(0.7);
      }
      textarea.not_empty ~ label, textarea:focus ~ label {
        transform: translateY(-42px) scale(0.7);
      }
    }
    input {
      line-height:36px;
    }
    textarea {
      height:100px;
      padding:0px;
    }
    .not_valid {
      position: absolute;
      bottom: 5px;
      left: 0;
      font-size: 14px;
      color: #ff6060;
      display:none;
    }
    input[name="email"]:not(:focus) ~ .not_valid {
      display:block;
    }
    .bottom_area {
      flex:0 1 100%;
      display:flex;
      align-items:center;
      flex-wrap:wrap;
      @media (max-width:840px) {
        display:block;
        width:100%;
      }
      .recaptcha {
        flex:1;
        @media (max-width:840px) {
          flex: 0 1 calc(100% - 80px);
          margin-bottom:20px;
          overflow-y: hidden;
          overflow-x: auto;
          height:0px;
        }
        opacity:0;
        transition: opacity 0.4s linear;
        pointer-events:none;
        &.visible {
          opacity:1;
          height:auto;
          pointer-events:initial;
        }
      }
      button {
        border:none;
        border-radius:44px;
        color:#fff;
        font-size:24px;
        padding:5px 40px;
        flex:0;
        @media (max-width:840px) {
          flex: 0 1 calc(100% - 80px);
          margin-left:initial;
        }
        outline:0;
        height:50px;
        margin-left:auto;
        background-color:#60AFFC;
        font-family: 'Coolvetica_b', sans-serif;
        cursor:pointer;
        transition:all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
        &:hover:not([disabled]) {
          background-color:#8ec7ff;
          transform:scale(1.1);
        }
        &:disabled {
          background-color:#d6d6d6;
        }
      }
    }
  }
  .formWrapper {
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
    transform:translateX(-50px);
    opacity:0;
    transform-origin: left;
    &:after {
      content:"";
      position:absolute;
      opacity: 0.57;
      background: #D8D8D8;
      border-radius: 100px;
      height:53px;
      width:80%;
      z-index:-1;
      bottom:-25px;
      left:0;
      right:0;
      margin:auto;
      filter:blur(18px);
    }
    &.visible {transform:translateX(0px); opacity:1;}
  }
`;

const lds_ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const lds_ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

const lds_ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const FormLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  div {
    position: absolute;
    top: 17px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 6px;
    animation: ${lds_ellipsis1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: ${lds_ellipsis2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: ${lds_ellipsis2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: ${lds_ellipsis3} 0.6s infinite;
  }
  ${props => props.type==='gif' ? `
    position: absolute;
    width: 64px;
    height: 64px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.3;
    transform: scale(2);
  ` : ''}
`;
