import styled, { keyframes } from "styled-components";
import triangle from "./assets/triangle.svg";

export const animationStep = 50;

const Rotating = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const Triangle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  max-width: 1152px;
  @media (max-width: 1300px) {
    max-width: 1000px;
  }
  width: 100%;
  @media (max-width: 992px) {
    width: 90%;
  }
  @media (max-width: 880px) {
    width: 95%;
  }
  height: 665px;
  background: url(${triangle}) no-repeat;
  background-size: contain;
  &.lower_triangle {
    position: absolute;
    bottom: 0;
    ${(props) =>
      props.inProject
        ? `
      bottom: -200px;
      `
        : ""}
    left:initial;
    right: 0px;
    top: initial;
    z-index: 1;
    width: 77%;
    transform: rotate(180deg);
    @media (max-width: 992px) {
      width: 100%;
    }
    @media (max-width: 720px) {
      width: 120%;
    }
    @media (max-width: 450px) {
      width: 140%;
    }
    @media (max-width: 400px) {
      width: 150%;
    }
  }
`;

export const AppCore = styled.div`
  position: relative;
  z-index: 2;
  top: 0;
  width: 950px;
  @media (max-width: 1100px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
  }
  @media (max-width: 480px) {
    padding: 0 20px;
    box-sizing: border-box;
  }
  margin: 0 auto;
  padding-top: 80px;
  opacity: 1;
  transition: opacity 2s;
  &.opening {
    /* opacity:0; */
  }
  .App-header {
    @media (max-width: 1100px) {
      position: relative;
      /* top:80px; */
      margin-top: 80px;
    }
    .inner {
      display: flex;
    }
    .App-logo {
      flex: 0 0 116px;
      @media (max-width: 992px) {
        margin-left: auto;
      }
    }
    nav {
      color: #fff;
      flex: 1;
      font-weight: 200;
      ul {
        padding: 0;
        li {
          .floating_star {
            position: absolute;
            width: 12px;
            height: 12px;
            top: -5px;
            right: -10px;
            animation: ${Rotating} 4s infinite linear;
          }
          margin-left: 24px;
          margin-right: 10px;
          letter-spacing: 1px;
          display: inline-block;
          cursor: pointer;
          transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
          position: relative;
          &:after {
            content: "";
            width: 100%;
            height: 2px;
            background: #fff;
            position: absolute;
            bottom: -7px;
            left: 0;
            transform: scale(0, 3);
            transform-origin: bottom left;
            transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          &:hover {
            &:after {
              width: 100%;
              transform: scale(1, 1);
            }
          }
        }
      }
      @media (max-width: 992px) {
        display: none;
      }
    }
    &.fixed {
      .App-logo {
        cursor: pointer;
        transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
        &:hover {
          transform: scale(1.1);
        }
      }
      transform: translateY(-55px);
      opacity: 0;
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.01s linear 0.5s;
      &.visible {
        opacity: 1;
        transform: translateY(0px);
        transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      }
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 998;
      background: rgba(95, 175, 255, 0.95);
      .inner {
        margin: 0 auto;
        width: 950px;
        .App-logo {
          padding: 15px;
          height: 25px;
          svg {
            height: 100%;
          }
        }
      }
    }
  }
  .fixedHeaderWrapper {
    height: 55px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 998;
    overflow: hidden;
  }
`;

export const HeaderBox = styled.div`
  width: 470px;
  position: absolute;
  right: 0;
  top: 270px;
  @media (max-width: 1100px) {
    right: 40px;
  }
  color: #424242;
  h2 {
    transform-origin: left center;
    transition: opacity 0.6s;
    font-size: 48px;
    font-weight: 200;
    letter-spacing: -1px;
    margin: 0px 0px 10px 0px;
    opacity: 1;
    .smaller {
      transform: scale(0);
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
      transform-origin: left center;
    }
    .bigger {
      line-height: 60px;
      transform: scale(0);
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
      transform-origin: left center;
    }
    &.active {
      .smaller {
        transform: scale(1);
      }
      .bigger {
        transform: scale(1);
      }
    }
    &.ending {
      opacity: 0;
    }
    .bigger {
      font-size: 72px;
    }
  }
  .slider {
    width: 100%;
    height: 5px;
    background: #f3f3f3;
    .progress {
      width: 0%;
      transition: width ${animationStep / 1000}s;
      height: 100%;
      background: #63b1ff;
    }
  }
  .desc {
    font-family: "Helvetica", sans-serif;

    margin-top: 15px;
    opacity: 0;
    transition: opacity 0.5s;
    .main_text {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.5px;
    }
    &.active {
      opacity: 1;
    }
    &.ending {
      opacity: 0;
    }
    .cta {
      line-height: 46px;
      background: #60aefb;
      display: inline-block;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      padding: 0 32px;
      border-radius: 30px;
      margin-top: 15px;
      transform: scale(0);
      transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      cursor: pointer;
    }
    &.active {
      .cta {
        transform: scale(1);
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  @media (max-width: 880px) {
    width: 320px;
    top: 170px;
    right: 10%;
    h2 {
      font-size: 36px;
      .bigger {
        font-size: 48px;
        line-height: 48px;
      }
    }
    .desc {
      .main_text {
        font-size: 18px;
        line-height: 24px;
      }
      .cta {
        font-size: 14px;
        padding: 0 24px;
        line-height: 36px;
      }
    }
  }
  @media (max-width: 667px) {
    right: 40px;
  }
  @media (max-width: 599px) {
    left: 0;
    right: 0;
    top: 400px;
    margin: auto;
  }
  @media (max-width: 480px) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
  @media (max-width: 340px) {
    h2 {
      font-size: 28px;
      .bigger {
        font-size: 32px;
        line-height: 32px;
      }
    }
    .desc {
      .main_text {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

const scaleIn = keyframes`
  0% { transform:scale(0); }
  100% { transform:scale(1); }
`;

export const Platform = styled.div`
  animation: 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s 1 ${scaleIn};
  width: 271px;
  height: 90px;
  border-radius: 100%;
  background: rgba(98, 176, 253, 0.12);
  position: absolute;
  bottom: 0;
  right: 200px;
  z-index: 1;
  @media (max-width: 599px) {
    right: 0;
    left: 50px;
    margin: auto;
    bottom: 85px;
    width: 200px;
    height: 66px;
  }
`;

export const Bullets = styled.div`
  position: absolute;
  top: 680px;
  left: 50px;
  @media (max-width: 1100px) {
    top: 600px;
  }
  ul {
    li {
      cursor: pointer;
      width: 14px;
      height: 14px;
      display: inline-block;
      background: rgba(99, 178, 255, 0.4);
      border-radius: 50%;
      margin-left: 15px;
      transition: background 0.3s;
      &.active {
        background: rgba(99, 178, 255, 1);
      }
      &:hover {
        background: rgba(99, 178, 255, 1);
      }
    }
  }
  @media (max-width: 1100px) {
    left: 90px;
  }
  @media (max-width: 992px) {
    left: 100px;
  }
  @media (max-width: 880px) {
    top: 390px;
  }
  @media (max-width: 740px) {
    left: 45px;
  }
  @media (max-width: 667px) {
    left: 10px;
  }
  @media (max-width: 599px) {
    top: 200px;
    left: initial;
    right: 5%;
    width: 60px;
    ul {
      li {
        width: 20px;
        height: 20px;
        margin-left: 0px;
        margin-bottom: 10px;
      }
    }
  }
`;

export const LottieWrapper = styled.div`
  width: 700px;
  height: 700px;
  margin-left: 0px;
  margin-bottom: 0px;
  position: relative;
  top: -220px;
  left: -225px;
  zindex: 2;
  pointer-events: none;
  div[role="button"] {
    z-index: 3;
  }
  @media (max-width: 992px) {
    left: -215px;
  }
  @media (max-width: 880px) {
    transform: scale(0.8);
    top: -350px;
    margin-bottom: -250px;
  }
  @media (max-width: 740px) {
    left: -270px;
  }
  @media (max-width: 667px) {
    left: -300px;
  }
  @media (max-width: 599px) {
    left: -30%;
    right: 0;
    margin: auto;
    margin-bottom: -80px;
    transform: scale(1);
    width: calc(100% + 40px + 15%);
  }
  @media (max-width: 340px) {
    margin-bottom: -120px;
  }
`;

export const MenuButton = styled.div`
  outline: 0;
  position: absolute;
  top: 80px;
  @media (max-width: 880px) {
    top: 40px;
  }
  left: 10%;
  z-index: 999;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
  &.is-active {
    position: fixed;
  }
`;

export const MobileMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 998;
  display: none;
  width: 100%;
  @media (max-width: 992px) {
    display: flex;
  }
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  /* transition:opacity 0.5s 1s; */
  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: ${(props) => props.thewidth}px;
    height: ${(props) => props.thewidth}px;
    transform-origin: ${(props) =>
      `${props.thewidth / 2}px ${props.thewidth / 2}px`};
    border-radius: 50%;
    transition: transform 0.5s;
    transform: scale(0);
    background-color: rgba(99, 177, 255, 0.95);
    backface-visibility: hidden;
    outline: 1px solid transparent;
  }
  ul {
    opacity: 0;
    flex: 1;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: center;
    position: relative;
    z-index: 5;
    transform: scale(0);
    transition: transform 0.4s 0.1s, opacity 0s 0.5s;
    li {
      cursor: pointer;
      display: block;
      font-size: 32px;
      color: #fff;
      margin-bottom: 10px;
    }
  }
  &.is-active {
    pointer-events: initial;
    opacity: 1;
    ul {
      opacity: 1;
      transform: scale(1);
      transition: transform 0.5s 0.2s;
      li {
        position: relative;
      }
      .floating_star {
        position: absolute;
        width: 20px;
        height: 20px;
        top: -5px;
        right: -10px;
        animation: ${Rotating} 4s infinite linear;
      }
    }
    &:before {
      backface-visibility: hidden;
      transform: scale(3);
      transform-origin: ${(props) =>
        `${props.thewidth / 2}px ${props.thewidth / 2}px`};
    }
  }
`;

const n_startSize = 90;

let lis = [];

for (let i = 0; i < 5; i++) {
  lis.push(`
    &.l${i + 1} {
      width:${n_startSize * (i + 2)}px;
      height:${n_startSize * (i + 2)}px;
      position:absolute;
      background:hsl(210,95.1%,${68.0 - 4.0 * (i + 1)}%);
      top:-${(n_startSize * (i + 2) - n_startSize) / 2}px;
      left:-${(n_startSize * (i + 2) - n_startSize) / 2}px;
      z-index:${18 - 2 * i};
      transition:all 0.4s cubic-bezier(.3,1.4,.5,.9);
      transition-delay: ${0.0 + 0.05 * i}s;
    }
  `);
}

export const CircleNav = styled.nav`
  position: fixed;
  top: 20px;
  right: 40px;
  @media (max-width: 600px) {
    right: 20px;
  }
  height: ${n_startSize}px;
  width: ${n_startSize}px;
  z-index: 998;
  transform: scale(0) rotate(0deg);
  transition: all 0.2s linear;

  &.fixed {
    &.open {
      transform: scale(1) rotate(20deg);
      transition-delay: 0s;
    }
    transform: scale(1) rotate(0deg);
    transition: all 0.4s cubic-bezier(0.3, 1.4, 0.5, 0.9);
    transition-delay: 0.4s;
  }
  .menu_toggle {
    background: #60aefb;
    border-radius: 50%;
    height: ${n_startSize}px;
    width: ${n_startSize}px;
    display: block;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 20;
    .hamburger,
    .hamburger-box {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }
  }
  .disc {
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    display: block;
    .title {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 15px;
      margin: auto;
      text-align: center;
      width: auto;
      white-space: nowrap;
      color: #fff;
      display: block;
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: #fff;
        position: absolute;
        bottom: -7px;
        left: 0;
        transform: scale(0, 3);
        transform-origin: bottom left;
        transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    &:hover {
      .title:after {
        width: 100%;
        transform: scale(1, 1);
      }
    }
    ${lis.join(" ")}
    transform:scale(0);
  }
  &.open {
    .disc {
      transform: scale(1);
    }
  }
`;
