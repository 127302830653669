// const be_server_root =
//   process.env.NODE_ENV === "production"
//     ? "https://andris.gauracs.com"
//     : "http://localhost:9000";

const be_server_root = "https://andris.gauracs.com";

const config = {
  be_server_root,
  be_server: be_server_root + "/api",
  be_server_files: `${be_server_root}/wp-content/uploads/2020/08/`,
};
module.exports = config;
