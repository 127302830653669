import styled, { keyframes } from 'styled-components';

const typewriter2 = keyframes`
  from {width: 0;}
  to {width: 4.25em;}
`;

const blinkTextCursor = keyframes`
  from{background:rgba(99,178,255,.75);}
  to{background:transparent;}
`;

export const AboutMe = styled.div`
  margin-top:200px;
  .blue_line {
    content:"";
    width:102px;
    height:5px;
    background:#63B2FF;
    display:block;
    margin-top:40px;
    @media (max-width:600px) {
      margin-bottom:60px;
    }
    &.smaller {
      width:50px;
      margin-bottom:65px;
      margin-top:10px;
    }
  }
  .titleWrapper {
    position:relative;
    overflow:hidden;
    height:180px;
  }
  .line-1 {
    height:87px;
  }
  .line-1 h3 {
    text-align: center;
    font-size:72px;
    @media (max-width:600px) {
      font-size:50px;
    }
    font-weight: 200;
    opacity:0;
    position:relative;
  }
  h4 {
    text-align: center;
    font-size:36px;
    font-weight: 200;
    text-align: left;
    margin-bottom:30px;
  }
  .line-1.active h3 {
    opacity:1;
    top: 50%;
    width: 3.7em;
    margin: 0;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
    position:relative;
    animation: ${typewriter2} 0.7s steps(10) 0s 1 normal both;
    &:after {
      content:"";
      display:block;
      position:absolute;
      right:0px;
      top:0;
      bottom:0;
      margin:auto;
      width:4px;
      height:52px;
      background:rgba(99,178,255,.75);
      animation: ${blinkTextCursor} 500ms steps(9) infinite normal;
    }
  }
`;

export const AboutMeArea = styled.div`
  display:flex;
  justify-content:space-between;
  @media (max-width: 1200px) {
    max-width:900px;
    margin:0 auto;
  }
  @media (max-width: 1000px) {
    max-width:800px;
    margin:0 auto;
    justify-content:flex-end;
  }
  @media (max-width: 840px) {
    flex-direction:column;
  }
  .experience, .education {
    flex:0 1 420px;
    @media (max-width: 1000px) {
      flex:0 1 350px;
    }
    @media (max-width: 840px) {
      flex:1;
      margin-left:50px;
    }
    @media (max-width: 480px) {
      flex:1;
      margin-left:20px;
    }
    h4 {
      color:#63B2FF;
      margin-top:0px;
      text-align:left;
      font-size:64px;
      font-weight:normal;
      @media (max-width: 840px) {
        margin-left:-50px;
      }
      @media (max-width: 480px) {
        margin-left:-20px;
      }
      @media (max-width:600px) {
        font-size:48px;
      }
    }
    .bullet_area {
      .character {
        width:200px;
        height:400px;
        position:absolute;
        left:-192px;
        top:20px;
        z-index:50;
        svg, div[role="button"] {
          height:100%;
          width:100%;
        }
      }
      position:relative;
      ul {
        padding:0;
        margin:0;
        list-style:none;
        font-family:'Helvetica', sans-serif;
        li {
          position:relative;
          padding-left:44px;
          margin-bottom:36px;
          &:before {
            content:"";
            background:#fff;
            position:absolute;
            left:0px;
            top:7px;
            width:14px;
            height:14px;
            border-radius:50%;
            border:3px solid #63B2FF;
            z-index:60;
            transform:scale(0);
            transition: transform 0.4s;
          }
          &:nth-child(1):before {
            transition-delay:0.2s;
          }
          &:nth-child(2):before {
            transition-delay:1.2s;
          }
          &:nth-child(3):before {
            transition-delay:2.2s;
          }
          &:not(:last-of-type) {
            &:after {
              width:3px;
              height:calc(100% + 36px);
              content:"";
              position:absolute;
              left:8px;
              top:8px;
              z-index:2;
              background:#63B2FF;
              transform:scaleY(0);
              transition: transform 0.4s;
              transform-origin: top;
            }
          }
          &:nth-child(1):after {
            transition-delay:0.6s;
          }
          &:nth-child(2):after {
            transition-delay:1.6s;
          }
          &:nth-child(3):after {
            transition-delay:2.6s;
          }
          .title {
            font-size:30px;
            font-weight:bold;
            color:#424242;
            line-height:36px;
            opacity:0;
            transition: opacity 0.1s;
          }
          .school {
            margin:3px 0 0 0;
            font-weight:bold;
            line-height:24px;
            color:#A7A7A7;
            font-size:20px;
            opacity:0;
            transition: opacity 0.1s;
          }
          .date {
            margin:3px 0;
            font-weight:normal;
            line-height:24px;
            color:#A7A7A7;
            font-size:20px;
            opacity:0;
            transition: opacity 0.1s;
          }
          .desc {
            font-weight:normal;
            line-height:24px;
            color:#424242;
            font-size:20px;
            opacity:0;
            transition: opacity 0.3s;
          }
          &:nth-child(1) {
            .title { transition-delay: 0.3s; }
            .date, .school { transition-delay: 0.4s; }
            .desc { transition-delay: 0.5s; }
          }
          &:nth-child(2) {
            .title { transition-delay: 1.3s; }
            .date, .school { transition-delay: 1.4s; }
            .desc { transition-delay: 1.5s; }
          }
          &:nth-child(3) {
            .title { transition-delay: 2.3s; }
            .date, .school { transition-delay: 2.4s; }
            .desc { transition-delay: 2.5s; }
          }
          @media (max-width: 992px) {
            .title { font-size:20px; line-height:20px;}
            .date, .school, .desc {
              font-size:16px;
              line-height:16px;
            }
            &:before { top: 0px; }
          }
          @media (max-width: 540px) {
            font-size:12px;
          }
        }
      }
      &.visible {
        li:not(:last-of-type) {
          &:after {
            transform:scaleY(1);
          }
        }
        li:before {
          transform:scale(1);
        }
        li {
          .title, .date, .school, .desc { opacity:1; }
        }
      }
    }
    .eductation .bullet_area {
      li {
        .date {
          margin:0 0 3px 0;
        }
      }
    }
    .subtitle {
      transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
      transform:scale(1.4);
      opacity:0;
      transform-origin: left;
      &.visible {
        transform:scale(1);
        opacity:1;
      }
    }
  }
`;
