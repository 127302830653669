export default `
.title {
  font-size:40px;
  @media (max-width:600px) {
    font-size:32px;
    line-height:30px;
    margin-bottom:3px;
  }
  font-family:'Helvetica', sans-serif;
  font-weight:800;
  letter-spacing: -2px;
}
.subtitle {
  @media (max-width:600px) {
    font-size:18px;
  }
  font-size:24px;
  font-weight:800;
  font-family:'Helvetica', sans-serif;
}
.tags {
  @media (max-width:600px) {
    font-size:14px;
  }
  font-size:20px;
  font-family:'Helvetica', sans-serif;
  font-weight:500;
  color:#949494;
}
`;
