import React, { Component } from "react";
import Lottie from "react-lottie";
import styled, { keyframes } from "styled-components";
import config from "./config.js";

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #60aefb;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .inner {
    flex-grow: 1;
    display: flex;
    max-width: 980px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    @media (max-width: 570px) {
      flex-wrap: wrap;
    }
    .anim {
      flex: 0 0 200px;
      margin-right: 100px;
      position: relative;
      .the_loader {
        top: 0;
        bottom: 0;
        margin: auto;
      }
      @media (max-width: 820px) {
        margin-right: 0px;
      }
      @media (max-width: 570px) {
        flex: 0 0 100%;
        div[role="button"] {
          transform: scale(0.7) translateY(-120px) translateX(-30px);
        }
        height: 360px;
      }
      svg {
        width: 400px !important;
      }
    }
    .wrap {
      flex: 0 1 500px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      h1 {
        margin: 20px 0 0 0;
        font-family: "Coolvetica_b", "Helvetica", sans-serif;
        font-weight: 200;
        font-size: 220px;
        color: #fff;
        display: block;
        .black {
          color: #424242;
        }
        text-align: center;
        flex: 0 0 auto;
      }
      .the_text {
        color: #fff;
        flex: 0 0 auto;
        font-size: 64px;
        .black {
          color: #424242;
        }
      }
      @media (max-width: 820px) {
        h1 {
          font-size: 160px;
        }
        .the_text {
          font-size: 42px;
        }
      }
      @media (max-width: 570px) {
        margin-bottom: 50px;
        h1 {
          font-size: 140px;
        }
        .the_text {
          font-size: 38px;
        }
      }
    }
  }
`;

const lds_ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const lds_ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

const lds_ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const Loader = styled.div`
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  transform: scale(2);
  div {
    position: absolute;
    top: 30px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 6px;
    animation: ${lds_ellipsis1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: ${lds_ellipsis2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: ${lds_ellipsis2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: ${lds_ellipsis3} 0.6s infinite;
  }
`;

class Route404 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: false,
    };
  }
  componentDidMount() {
    fetch(config.be_server_root + "/animations/404-1-y9h3nTn1R8UG.json").then(
      (response) => {
        response.json().then((json) => {
          this.setState({ animation: json });
        });
      }
    );
  }
  render() {
    let defaultOptions = {
      autoplay: true,
      animationData: this.state.animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      loop: true,
    };
    let { animation } = this.state;
    return (
      <Container>
        <div className="inner">
          <div className={`anim`}>
            {animation ? (
              <Lottie
                ref={"404lottie"}
                options={defaultOptions}
                width={200}
                height={600}
                style={{
                  pointerEvents: "none",
                  //position:'absolute',
                  //top:0,
                  //left:0,
                  //right:0,
                  //bottom:0,
                  //margin:'auto'
                }}
              />
            ) : (
              <Loader className="the_loader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </Loader>
            )}
          </div>
          <div className="wrap">
            <h1 className="fourofour">
              404<span className="black">.</span>
            </h1>
            <div className="the_text">
              {"Page not found"}
              <span className="black">.</span>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}
export default Route404;
