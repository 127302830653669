import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import Lottie from "react-lottie";
import boxParams from "./boxParams";

const Rotating = keyframes`
  0% { transform:rotate(15deg); }
  25% { transform:rotate(-15deg); }
  50% { transform:rotate(15deg); }
  75% { transform:rotate(-15deg); }
  100% { transform:rotate(0deg); }
`;

const BackgroundChange = keyframes`
  0% { background-color:transparent; opacity:1;  }
  10% { background-color:white; opacity:1;  }
  50% { background-color:white; opacity:1;  }
  80% { background-color:white; opacity:1;  }
  100% { background-color:transparent; opacity:0; }
`;

const Flying = styled.div`
  text-decoration: none;
  color: rgb(66, 66, 66);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 910;
  pointer-events: none;
  overflow: hidden;
  .innerBody {
    left: ${(props) => props.posx}px;
    top: ${(props) => props.posy}px;
    /* left:0;
    top:0; */
    width: ${(props) => props.thewidth - 40}px;
    visibility: hidden;
    position: absolute;
  }
  &.opening {
    opacity: 1;
    visibility: visible;
    .innerBody {
      opacity: 1;
      visibility: visible;
    }
  }
  .boxBody .inner {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: -4px 8px 20px 0 rgba(34, 34, 34, 0.18);
    padding: 20px;
    width: 100%;
    transition: box-shadow 2.65s;
  }
  &.opening {
    animation: ${BackgroundChange} 3.5s;
    animation-fill-mode: forwards;
    .boxBody {
      animation: ${Rotating} 3s;
      .inner {
        box-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
      }
    }
  }
  .arm {
    box-shadow: -4px 8px 20px 0 rgba(34, 34, 34, 0.18);
  }
  .blue {
    color: rgba(99, 178, 255, 1);
  }
  ${boxParams}
  .hanging_animation {
    transform: translateY(0);
    opacity: 1;

    margin-left: 0px;
    margin-bottom: 0px;
    position: absolute;
    left: -330px;
    top: -250px;
    pointer-events: none;
    &.one {
      z-index: -1;
    }
    &.two {
      z-index: 1;
    }
  }
  &.opening {
    .innerBody {
      transition: left 2s cubic-bezier(0.19, 1, 0.22, 1),
        top 2s cubic-bezier(0.19, 1, 0.22, 1), transform 2s;
      left: calc(50% - ${(props) => props.thewidth / 2}px);
      margin: auto;
      top: 60px;
    }
    .hanging_animation {
      transition: transform 1.5s 2.5s, opacity 0.3s 3.5s;
      opacity: 0;
      transform: translateY(calc(100vh));
    }
  }
  &.cancel {
    z-index: 910;
  }
`;

class FlyingMan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startAnimating: false,
    };
  }
  componentDidMount() {
    //console.log('flying man mounted', this.refs)
    this.props.passRefUpward(this.refs);
    //setTimeout(()=>{this.setState({startAnimating: true})},100);
  }
  render() {
    let defaultOptionsHanging = {
      autoplay: false,
      animationData: this.props.animations[3],
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      loop: false,
    };

    let defaultOptionsHangingArm = {
      autoplay: false,
      animationData: this.props.animations[4],
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
      loop: false,
    };
    let { mockup, animating } = this.props;
    return (
      <Flying
        id="mockup_div"
        posx={mockup.posX}
        posy={mockup.posY}
        thewidth={mockup.width}
        className={animating ? "opening" : ""}
      >
        <div className="innerBody">
          <div className="boxBody">
            <div className="hanging_animation one" id={"hanging"}>
              <Lottie
                ref={"hanging"}
                options={defaultOptionsHanging}
                width={700}
                height={900}
              />
            </div>
            <div className="hanging_animation two" id={"hanging1"}>
              <Lottie
                className="arm hanging_animation"
                ref={"hanging1"}
                options={defaultOptionsHangingArm}
                width={700}
                height={900}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: () => {},
                  },
                ]}
              />
            </div>
            <div className="inner">
              <div className="title">
                {mockup.title}
                <span className="blue">.</span>
              </div>
              <div className="subtitle">{mockup.subtitle}</div>
              <div className="tags">{mockup.tags}</div>
            </div>
          </div>
        </div>
      </Flying>
    );
  }
}

export default FlyingMan;
