import styled, { keyframes } from "styled-components";
import boxParams from "./boxParams";

const typewriter = keyframes`
  from {width: 0;}
  to {width: 3.7em;}
`;

const blinkTextCursor = keyframes`
  from{background:rgba(99,178,255,.75);}
  to{background:transparent;}
`;

const MainArea = styled.div`
  color: #424242;
  .blue {
    color: rgba(99, 178, 255, 1);
  }
  .projects {
    margin-bottom: 40px;
    .blue_line {
      content: "";
      width: 102px;
      height: 5px;
      background: #63b2ff;
      display: block;
      margin-top: 40px;
      @media (max-width: 600px) {
        margin-bottom: 60px;
      }
      &.smaller {
        width: 50px;
        margin-bottom: 65px;
        margin-top: 10px;
      }
    }
    .titleWrapper {
      position: relative;
      overflow: hidden;
      height: 180px;
    }
    .line-1 {
      height: 87px;
    }
    .line-1 h3 {
      text-align: center;
      font-size: 72px;
      @media (max-width: 600px) {
        font-size: 50px;
      }
      font-weight: 200;
      opacity: 0;
      position: relative;
    }
    h4 {
      text-align: center;
      font-size: 36px;
      font-weight: 200;
      text-align: left;
      margin-bottom: 30px;
    }
    .line-1.active h3 {
      opacity: 1;
      top: 50%;
      width: 3.7em;
      margin: 0;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      transform: translateY(-50%);
      position: relative;
      animation: ${typewriter} 0.7s steps(10) 0s 1 normal both;
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 4px;
        height: 52px;
        background: rgba(99, 178, 255, 0.75);
        animation: ${blinkTextCursor} 500ms steps(9) infinite normal;
      }
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .project_box {
        flex: 0 0 48%;
        @media (max-width: 1020px) {
          flex: 0 0 500px;
        }
        @media (max-width: 600px) {
          box-sizing: border-box;
          flex: 0 0 100%;
        }
        position: relative;
        opacity: 0;
        .img_wrap {
          position: relative;
          width: 320px;
          height: 244px;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden;
          box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.22);
          transform: translateY(20px);
          transition: opacity 0.2s linear,
            transform 1s cubic-bezier(0.19, 1, 0.22, 1);
          @media (max-width: 600px) {
            margin: 0 auto;
            max-width: 320px;
            width: calc(100% - 20px);
          }
          @media (max-width: 400px) {
            background-position: center center;
          }
          .the_gif {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background-size: cover;
          }
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-image: linear-gradient(
              -180deg,
              rgba(255, 255, 255, 0) 16%,
              rgba(255, 255, 255, 0.7) 100%
            );
          }
          video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            min-width: 101%;
            height: 101%;
            z-index: -1;
          }
        }
        .desc_box {
          transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
          text-decoration: none;
          color: rgb(66, 66, 66);
          position: absolute;
          bottom: -25px;
          right: 0;
          &.hidden {
            display: none;
          }
          width: ${(props) => props.boxtitlewidth}px;
          @media (max-width: 600px) {
            box-sizing: border-box;
            width: 100%;
          }
          background: rgba(255, 255, 255, 0.9);
          box-shadow: -4px 8px 20px 0 rgba(34, 34, 34, 0.18);
          padding: 20px;
          ${boxParams}
        }
        margin-bottom: 70px;
        transition: opacity 1s;
        &.visible {
          opacity: 1;
          .img_wrap {
            margin-left: 10px;
            transform: translateY(0);
          }
          .img_wrap,
          .desc_box {
            opacity: 1;
          }
        }
        cursor: pointer;
        &:hover {
          .img_wrap {
            opacity: 0.8;
            transform: translateY(-10px);
          }
          .desc_box {
            background: rgba(255, 255, 255, 0.6);
            .title {
              color: #63b2ff;
            }
          }
        }
      }
    }
    .web-development {
      h4 {
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
        transform: scale(1.4);
        opacity: 0;
        transform-origin: left;
      }
      .blue_line.smaller {
        width: 0px;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
      }
      &.visible {
        h4 {
          transform: scale(1);
          opacity: 1;
        }
        .blue_line.smaller {
          width: 50px;
        }
      }
    }
  }
`;

export default MainArea;
