import React, { Component } from "react";
import Home from "./Home";
import Project from "./Project";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import config from "./config.js";
import Route404 from "./404.js";

import logo from "./assets/logo.svg";
import triangle from "./assets/triangle.svg";

import FlyingMan from "./FlyingMan";

import { ApolloClient, gql, InMemoryCache } from "@apollo/client";

const boxtitlewidth = 420;

const apolloClient = new ApolloClient({
  uri: `${config.be_server}/graphql`,
  cache: new InMemoryCache(),
});

window.mobileAndTabletcheck = function () {
  var check = false;
  // eslint-disable-next-line
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y:scroll;
    overflow-x:hidden;
    @media (max-width:992px) {
      &.mobile-menu-open {
        overflow:hidden;
      }
    }
  }

  .first_loader,
  .first_loader:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }
  .first_loader {
    margin: 0px 0px 0px 6px;
    display:inline-block;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.3em solid rgba(255, 255, 255, 0.2);
    border-right: 0.3em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
    border-left: 0.3em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .back_button_wrapper {
    max-width:940px;
    width:100%;
    height:50px;
    position:absolute;
    top:0;
    left:0;
    right:0;
    margin:auto;
  }

  .centered-container {
    position:absolute;
    top:15px;
    left:20px;
    &.totop {
      top:initial;
      left:initial;
      bottom:-15px;
      right:-10px;
      position:fixed;
      z-index:900;
      opacity:0;
      padding:30px;
      padding-top:40px;
      padding-bottom:20px;
      border-radius: 20px;
      transform:scale(0);
      pointer-events: none;
      transition:all 0.4s cubic-bezier(.3,1.4,.5,.9);
      background-color:transparent;
      cursor:pointer;
      &.active {
        opacity:1;
        transform:scale(1);
        pointer-events: initial;
      }
      &:hover {
        background-color:rgba(255,255,255,0.9);
        .link--arrowed .arrow-icon {
          transform: translate3d(0, -20px, 0) rotate(-90deg);
        }
        .link--arrowed span.back {
          transform: translate3d(0, -10px, 0);
          opacity:1;
        }
        .link--arrowed span.question_mark {
                  opacity:1;
        }
        .link--arrowed .arrow-icon--circle {
          stroke-dashoffset: 0;
        }
      }
      .link {
        flex-direction:column;
        align-items: flex-end;
        span.back {
          font-size:20px;
          display:block;
          text-align: center;
          position:relative;
          opacity:0;
          left:-10px;
        }
        span.totop_text {
          display:block;
          font-size:14px;
          line-height:14px;
        }
        span.question_mark {
          position:absolute;
          right:-20px;
          top:-2px;
          transform:rotate(15deg);
          font-size:38px;
        }
      }
      .link--arrowed .arrow-icon {
        transform: translate3d(0, 30px, 0) rotate(-90deg);
      }
    }
  }

  .link {
    cursor: pointer;
    font-weight: 400;
    text-decoration: none;
    display:flex;
    align-items: center;
    span.back {
      flex:1;
      font-size:24px;
      transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      color:rgba(99,178,255,1);
    }
    span.question_mark {
      padding-left:2px;
      color:#424242;
      transition: all 0.3s ease;
      opacity:0;
    }
  }

  .arrow-icon {
    flex:1;
  }
  .link--arrowed .arrow-icon {
    position: relative;
    top: -1px;
    transition: transform 0.3s ease;
    vertical-align: middle;
    transform: rotate(180deg);
  }
  .link--arrowed .arrow-icon--circle {
    transition: stroke-dashoffset .3s ease;
    stroke-dasharray: 95;
    stroke-dashoffset: 95;
  }
  .link--arrowed:hover .arrow-icon {
            transform: translate3d(5px, 0, 0) rotate(180deg);
  }
  .link--arrowed:hover span.back {
            transform: translate3d(15px, 0, 0);
  }
  .link--arrowed:hover span.question_mark {
            opacity:1;
  }
  .link--arrowed:hover .arrow-icon--circle {
    stroke-dashoffset: 0;
  }
`;

const swoosh = keyframes`
  0% { transform: scaleX(0); transform-origin:right; }
  50% { transform: scaleX(1); transform-origin:right; }
  60% { transform: scaleX(1); transform-origin:left; }
  100% { transform: scaleX(0); transform-origin:left; }
`;

const TransitionBlock = styled.div`
  z-index: 999;
  background-color: rgba(99, 178, 255, 1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: block;
  content: "";
  transform: scaleX(0);
  &.active {
    animation: ${swoosh} 1s;
    animation-fill-mode: forwards;
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #63b2ff;
  opacity: 1;
  z-index: 9999;
  transition: opacity 1s;
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: auto;
  }
  &.opening {
    opacity: 0;
  }
  .progress {
    color: #fff;
    position: absolute;
    top: 140px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 20px;
    transition: all 0.35s;
    text-align: center;
  }
`;

const LoadRing = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
`;

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress } = this.props;

    const strokeDashoffset =
      this.circumference - (progress / 100) * this.circumference;

    return (
      <LoadRing height={radius * 2} width={radius * 2}>
        <circle
          stroke="white"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + " " + this.circumference}
          style={{ strokeDashoffset: strokeDashoffset || 0 }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </LoadRing>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backFromProjects: false,
      enterFromStart: false,
      projectImages: [],
      flyingAnimation: false,
      justLoaded: false,
      mockup: {
        width: 400,
        posX: 0,
        posY: 0,
        title: "",
        subtitle: "",
        tags: "",
        i: 0,
      },
      resetAnimation: "",
      addFinished: "",
      web_data: "",
      new_web_data: "",
      fixedProjectPage: "",
      siteLoaded: false,
      flyingTimeout: "",
      backFromProjectsTimeout: "",
      transitionBlockTimeout: "",
      openingTimeout: "",
      siteOpenTimeout: "",
      homeKey: Date.now(),
      animations: ["", "", ""],
      isMobile: false,
    };
    this.isBackFromProjects = this.isBackFromProjects.bind(this);
    this.toggleProjects = this.toggleProjects.bind(this);
    this.checkIfLeaving = this.checkIfLeaving.bind(this);
    this.openProject = this.openProject.bind(this);
    this.getRefsFromChild = this.getRefsFromChild.bind(this);
    this.resetAnimation = this.resetAnimation.bind(this);
    this.justLoaded = this.justLoaded.bind(this);
    this.resetTimeouts = this.resetTimeouts.bind(this);
  }
  resetTimeouts() {
    let s = this.state;
    clearTimeout(s.flyingTimeout);
    clearTimeout(s.resetAnimation);
    clearTimeout(s.fixedProjectPage);
    clearTimeout(s.backFromProjectsTimeout);
    clearTimeout(s.transitionBlockTimeout);
    clearTimeout(s.openingTimeout);
    clearTimeout(s.siteOpenTimeout);
  }
  componentWillUnmount() {
    this.resetTimeouts();
  }
  justLoaded() {
    this.setState({ justLoaded: true });
  }
  openProject(title, subtitle, tags, i, link) {
    //document.getElementById('mockup_div').classList.remove('opening');
    let theRef = document.getElementById(`project_${i}`);
    let pos = theRef.getBoundingClientRect();
    this.state.flyingManRefs.hanging.anim.goToAndPlay(1);
    this.state.flyingManRefs.hanging1.anim.goToAndPlay(1);
    this.setState({
      mockup: {
        width: theRef.offsetWidth,
        posX: pos.left,
        posY: pos.top,
        title: title,
        subtitle: subtitle,
        tags: tags,
        i: i,
      },
      flyingTimeout: setTimeout(() => {
        this.setState({ flyingAnimation: true });
        theRef.style.display = "none";
        document.getElementById("project_page").style.position = "fixed";
        this.setState({
          fixedProjectPage: setTimeout(() => {
            document.getElementById("project_page").style.position = "initial";
          }, 3200),
        });
      }, 100),
    });
  }
  isBackFromProjects(node) {
    if (node.classList.contains("project_page")) {
      //window.scrollTo(0, 0);
      this.state.new_web_data.projects.forEach((e, i) => {
        document.getElementById(`project_${i}`).style.display = "initial";
      });
      clearTimeout(this.state.resetAnimation);
      clearTimeout(this.state.fixedProjectPage);
      this.setState({
        backFromProjectsTimeout: setTimeout(() => {
          this.setState({ backFromProjects: true, flyingAnimation: false });
        }, 500),
      });
    }
  }
  resetAnimation() {
    //console.log('reseting');
    this.setState({ flyingAnimation: false });
  }
  toggleProjects() {
    this.setState({ backFromProjects: false });
  }
  getRefsFromChild(childRefs) {
    this.setState({
      flyingManRefs: childRefs,
    });
  }
  checkIfLeaving(node) {
    if (document.getElementById("mockup_div").classList.contains("opening")) {
      this.setState({ homeKey: Date.now() });
      if (node.classList.contains("project_page")) {
        document.getElementById("mockup_div").classList.add("cancel");
        document.getElementById("transition_block").classList.add("active");
        this.setState({
          transitionBlockTimeout: setTimeout(() => {
            document
              .getElementById("transition_block")
              .classList.remove("active");
          }, 1000),
        });
        clearTimeout(this.state.resetAnimation);
        clearTimeout(this.state.fixedProjectPage);
        document.getElementById("project_page").style.position = "initial";
      }
    } else {
      //document.getElementById('project_page').style.position = 'initial';
      if (document.querySelector(".project_page .inner"))
        document
          .querySelector(".project_page .inner")
          .classList.remove("leaving");
      if (node.classList.contains("project_page")) {
        document.getElementById("transition_block").classList.add("active");
        console.log("adding active to transition block (no opening)");
        this.setState({
          transitionBlockTimeout: setTimeout(() => {
            document
              .getElementById("transition_block")
              .classList.remove("active");
            console.log("removing active from transition block (no opening)");
          }, 1000),
        });
      }
    }
    /* setTimeout(()=>{
      if (node.classList.contains('Home') && !document.getElementById('mockup_div').classList.contains('opening')) {
        document.getElementById('project_page').style.position = 'initial';
      }
    },200); */
  }
  componentDidMount() {
    //console.log('app mounted');
    // let the_path = `${config.server}/files/projects/thumbnails/`;
    // let project_images = web_data.projects.map(
    //   (e) => `${the_path}${e.thumb}.jpg`
    // );
    this.setState({
      // projectImages: project_images,
      // web_data,
      loadingProgress: 0,
      opening: false,
      siteOpened: false,
      isMobile: window.mobileAndTabletcheck(),
    });
    apolloClient
      .query({
        query: gql`
          query Query {
            projects {
              title
              color
              gif {
                url
              }
              gif_video {
                url
              }
              link
              mobile_thumb {
                url
              }
              pc_thumb {
                url
              }
              provider
              subtitle
              tags
              thumb {
                url
              }
              type
              url
              video_url
              content {
                content
                img {
                  url
                }
                poster {
                  url
                }
                title
                type
                priority_img
                video {
                  url
                }
              }
              color
            }
            educations {
              date
              desc
              school
              title
            }
            experiences {
              date
              desc
              title
              title_url
            }
            generalDataInfos {
              head_title
              home_title
            }
            headerInfoButtons {
              buttonLink
              buttonText
            }
            headerInfoSubtexts {
              subtext
            }
            headerInfoTexts {
              textOne
              textTwo
            }
            animations {
              title
              file {
                url
              }
            }
          }
        `,
      })
      .then((result) => {
        this.setState({ new_web_data: result.data });
        const { animations } = result.data;
        let assets = [
          {
            f: animations.find((a) => a.title === "animation_1").file.url,
            t: "json",
            pos: 0,
          },
          {
            f: animations.find((a) => a.title === "animation_2").file.url,
            t: "json",
            pos: 1,
          },
          {
            f: animations.find((a) => a.title === "animation_3").file.url,
            t: "json",
            pos: 2,
          },
          {
            f: animations.find((a) => a.title === "hanging").file.url,
            t: "json",
            pos: 3,
          },
          {
            f: animations.find((a) => a.title === "hangingArm").file.url,
            t: "json",
            pos: 4,
          },
          { f: triangle, t: "img" },
          { f: logo, t: "img" },
        ];
        assets = assets.concat(
          result.data.projects.map((e) => {
            return {
              f: e.thumb.url,
              //f: `${config.server}/files/projects/thumbnails/${e.thumb}.jpg`,
              t: "img",
            };
          })
        );
        let promises = [];
        assets.forEach((e) => {
          promises.push(
            new Promise((resolve) => {
              fetch(e.f).then((response) => {
                //console.log(response);
                if (e.t === "json") {
                  response.json().then((json) => {
                    let newArray = this.state.animations;
                    newArray[e.pos] = json;
                    this.setState({
                      loadingProgress:
                        this.state.loadingProgress + 100 / assets.length,
                      animations: newArray,
                    });
                    resolve(e);
                  });
                } else if (e.t === "img") {
                  let im = new Image();
                  im.src = e.f;
                  im.onload = () => {
                    this.setState({
                      loadingProgress:
                        this.state.loadingProgress + 100 / assets.length,
                    });
                    resolve(e);
                  };
                }
              });
            })
          );
        });
        Promise.all(promises).then((e) => {
          //console.log(e);
          this.setState({
            siteLoaded: true,
          });
          //console.log(this.state.animations);
          this.setState({
            openingTimeout: setTimeout(
              () => this.setState({ opening: true }),
              500
            ),
            siteOpenTimeout: setTimeout(
              () => this.setState({ siteOpened: true }),
              1000
            ),
          });
        });
      });
  }
  render() {
    let {
      backFromProjects,
      projectImages,
      flyingAnimation,
      // web_data,
      justLoaded,
      siteLoaded,
      loadingProgress,
      siteOpened,
      new_web_data,
    } = this.state;
    return (
      <div>
        <GlobalStyle />
        {siteLoaded && (
          <Router>
            <Route
              render={({ history, location, match }) => {
                if (location.pathname === "/" && !justLoaded) {
                  location.key = Date.now();
                }
                return (
                  <div>
                    <TransitionBlock
                      id={"transition_block"}
                      className={"transition_block"}
                    />
                    <FlyingMan
                      animating={flyingAnimation}
                      animations={this.state.animations}
                      mockup={this.state.mockup}
                      passRefUpward={this.getRefsFromChild}
                    />
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key}
                        classNames="fade"
                        onExit={(node) => {
                          this.checkIfLeaving(node);
                          this.isBackFromProjects(node);
                        }}
                        onExited={(node) => {
                          this.isBackFromProjects(node);
                        }}
                        timeout={1000}
                      >
                        <Switch location={location}>
                          <PropsRoute
                            exact
                            path="/"
                            isMobile={this.state.isMobile}
                            key={this.state.homeKey}
                            animations={this.state.animations}
                            animating={flyingAnimation}
                            desc_box={this.state.mockup.i}
                            component={Home}
                            boxtitlewidth={boxtitlewidth}
                            justLoaded={this.justLoaded}
                            backFromProjects={backFromProjects}
                            toggleProjects={this.toggleProjects}
                            projectImages={projectImages}
                            openProject={this.openProject}
                            // web_data={web_data}
                            new_web_data={new_web_data}
                          />
                          (
                          {new_web_data &&
                            new_web_data.projects.map((e, i) => (
                              <PropsRoute
                                key={i}
                                projectTitle={e.title}
                                details={e}
                                isMobile={this.state.isMobile}
                                boxtitlewidth={boxtitlewidth}
                                exact
                                path={`/${e.link}`}
                                component={Project}
                                // web_data={[]}
                                resetAnimation={this.resetAnimation}
                                justLoaded={this.state.justLoaded}
                                flyingAnimation={this.state.flyingAnimation}
                                locationData={location}
                                new_web_data={new_web_data}
                              />
                            ))}
                          )
                          <Route
                            path="*"
                            exact={true}
                            component={Route404}
                            new_web_data={new_web_data}
                          />
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  </div>
                );
              }}
            />
          </Router>
        )}
        {!siteOpened && (
          <LoadingContainer className={this.state.opening ? "opening" : ""}>
            <ProgressRing radius={60} stroke={5} progress={loadingProgress} />
            <svg
              className="logo"
              viewBox="0 0 117 103"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M72.592 99l-.432-9.504c-2.304 4.032-6 7.176-11.088 9.432-5.088 2.256-10.944 3.384-17.568 3.384-6.624 0-12.6-1.416-17.928-4.248-5.328-2.832-9.888-6.624-13.68-11.376S5.2 76.464 3.184 70.272.16 57.672.16 51.048c0-7.008 1.008-13.608 3.024-19.8s4.992-11.592 8.928-16.2C16.048 10.44 20.944 6.816 26.8 4.176c5.856-2.64 12.672-3.96 20.448-3.96 5.664 0 10.968.816 15.912 2.448 4.944 1.632 9.264 3.888 12.96 6.768 3.696 2.88 6.672 6.312 8.928 10.296 2.256 3.984 3.576 8.376 3.96 13.176h-20.16c-.288-2.208-1.056-4.296-2.304-6.264a19.224 19.224 0 0 0-4.752-5.112c-1.92-1.44-4.152-2.592-6.696-3.456a24.242 24.242 0 0 0-7.848-1.296c-8.064 0-14.52 3-19.368 9-4.848 6-7.272 14.712-7.272 26.136 0 3.936.504 7.896 1.512 11.88 1.008 3.984 2.592 7.584 4.752 10.8 2.16 3.216 4.92 5.832 8.28 7.848 3.36 2.016 7.344 3.024 11.952 3.024 3.264 0 6.312-.576 9.144-1.728 2.832-1.152 5.304-2.736 7.416-4.752A22.369 22.369 0 0 0 68.632 72c1.2-2.64 1.8-5.448 1.8-8.424H49.264V47.592h39.888V99h-16.56zm23.616 0V79.56h20.016V99H96.208z"
                fill="#FFF"
                fillRule="evenodd"
              />
            </svg>
            <div className="progress">
              {`${Math.round(loadingProgress, 2)}%`}
              <div className="first_loader">Loading...</div>
            </div>
          </LoadingContainer>
        )}
      </div>
    );
  }
}

export default App;
