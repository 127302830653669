import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import sanitizeHtml from "sanitize-html";

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1080px) {
    width: 600px;
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    flex-wrap: wrap;
    width: 100%;
  }
  .video_container {
    position: relative;
    margin-top: 50px;
    flex: 0 0 100%;
    .iframe_wrapper {
      box-shadow: -8px -4px 35px 0 rgba(0, 0, 0, 0.42);
      border-radius: 10px;
      position: relative;
      padding-bottom: 56.25%;
      transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
      transform: scale(0) rotate(45deg);
      overflow: hidden;
      z-index: 4;
      background-color: #000;
      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    iframe {
      width: 100%;
      position: relative;
    }
    &.ready {
      .iframe_wrapper {
        transform: scale(1) rotate(0deg);
      }
    }
    &:after {
      z-index: 1;
      content: "";
      width: 400px;
      height: 400px;
      @media (max-width: 1080px) {
        width: 220px;
        height: 220px;
      }
      @media (max-width: 800px) {
        width: 220px;
        height: 220px;
        top: -13%;
        left: -10%;
      }
      @media (max-width: 640px) {
        width: 220px;
        height: 220px;
        top: -13%;
        left: -7%;
        margin: auto;
      }
      @media (max-width: 540px) {
        width: 220px;
        top: -13%;
        height: 220px;
      }
      @media (max-width: 480px) {
        width: 170px;
        height: 170px;
        top: -20%;
      }
      @media (max-width: 350px) {
        width: 100px;
        height: 100px;
      }
      border-radius: 50%;
      position: absolute;
      top: -15%;
      left: -10%;
      background-color: ${(props) => props.thecolor};
      transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform-origin: bottom;
      transform: scale(0);
    }
    &.ready {
      &:after {
        transform: scale(1);
      }
    }
  }
`;

const PcLook = styled.div`
  margin-top: 50px;
  flex: 0 1 500px;
  @media (max-width: 1080px) {
    flex: 0 1 350px;
  }
  @media (max-width: 800px) {
    flex: 0 1 100%;
    box-sizing: border-box;
  }
  position: relative;
  img {
    width: 100%;
    z-index: 2;
    position: relative;
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
    transform: scale(0) rotate(-45deg);
  }
  &.ready {
    img {
      transform: scale(1) rotate(0deg);
    }
  }
  &:after {
    z-index: 1;
    content: "";
    width: 400px;
    height: 400px;
    @media (max-width: 1080px) {
      width: 220px;
      height: 220px;
    }
    @media (max-width: 800px) {
      width: 400px;
      height: 400px;
      top: -5%;
      left: -5%;
    }
    @media (max-width: 640px) {
      width: 300px;
      height: 300px;
      top: -5%;
      left: -5%;
      margin: auto;
    }
    @media (max-width: 540px) {
      width: 250px;
      height: 250px;
    }
    @media (max-width: 480px) {
      width: 200px;
      height: 200px;
    }
    border-radius: 50%;
    position: absolute;
    top: -15%;
    left: -15%;
    background-color: ${(props) => props.thecolor};
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-origin: bottom;
    transform: scale(0);
  }
  &.ready {
    &:after {
      transform: scale(1);
    }
  }
`;
const MobileLook = styled.div`
  margin-top: 50px;
  flex: 0 1 300px;
  @media (max-width: 1080px) {
    flex: 0 1 200px;
  }
  @media (max-width: 800px) {
    flex: 0 1 100%;
  }
  position: relative;
  img {
    width: 100%;
    @media (max-width: 800px) {
      width: 80%;
      margin: 0 auto;
    }
    z-index: 5;
    position: relative;
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
    transform: scale(0) rotate(45deg);
  }
  &.ready {
    img {
      transform: scale(1) rotate(0deg);
    }
  }
  &:after {
    z-index: 4;
    content: "";
    width: 300px;
    height: 300px;
    @media (max-width: 1080px) {
      width: 200px;
      height: 200px;
    }
    border-radius: 50%;
    position: absolute;
    top: 10%;
    @media (max-width: 800px) {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 400px;
      height: 400px;
    }
    @media (max-width: 640px) {
      width: 300px;
      height: 300px;
    }
    @media (max-width: 540px) {
      width: 250px;
      height: 250px;
    }
    @media (max-width: 380px) {
      width: 200px;
      height: 200px;
    }
    right: 0;
    background-color: ${(props) => props.thecolor};
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transform: scale(0);
    transform-origin: top;
  }
  &.ready {
    &:after {
      transform: scale(1);
    }
  }
`;

const TextField = styled.div`
  font-family: "Helvetica", sans-serif;
  font-weight: normal;
  line-height: 32px;
  color: #757575;
  font-size: 20px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s linear;
  a {
    &.project_url {
      padding-left: 10px;
      @media (max-width: 480px) {
        padding-left: 0px;
      }
    }
    color: #63b2ff;
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
  &.visible {
    opacity: 1;
    pointer-events: initial;
  }
  p {
    margin: 50px auto;
    text-align: justify;
    &.project_url_p {
      strong {
        @media (max-width: 480px) {
          display: block;
        }
      }
    }
  }
  .video_section {
    display: flex;
    flex-wrap: wrap;
    .video {
      flex: 0 0 100%;
      video {
        width: 100%;
        height: auto;
      }
    }
    .text {
      flex: 0 0 100%;
    }
  }
  .image_section {
    padding: 0 40px 40px 40px;
    @media (max-width: 480px) {
      padding: 0 0px 40px 0px;
    }
    box-sizing: border-box;
    img {
      width: 100%;
      box-shadow: 1px 13px 48px -2px rgba(176, 176, 176, 0.69);
    }
    h3 {
      text-align: center;
      padding: 20px;
    }
  }
  .page_footer {
    width: 100%;
    margin: 20px 0 100px 0;
    display: flex;
    padding-top: 20px;
    border-top: 1px solid rgba(99, 178, 255, 0.35);
    font-family: "Coolvetica_b", sans-serif;
    .left-side {
      flex: 0 1 50%;
      text-align: left;
      color: rgba(99, 178, 255, 1);
      font-weight: bold;
      letter-spacing: 1px;
    }
    .right-side {
      text-align: right;
      flex: 0 1 50%;
    }
    .App-logo,
    .site_link {
      cursor: pointer;
      display: inline-block;
      transition: all 0.2s ease;
      transform: scale(1);
      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

const lds_ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const lds_ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

const lds_ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const Loader = styled.div`
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  transform: scale(2);
  div {
    position: absolute;
    top: 30px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: ${(props) => props.thecolor};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 6px;
    animation: ${lds_ellipsis1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: ${lds_ellipsis2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: ${lds_ellipsis2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: ${lds_ellipsis3} 0.6s infinite;
  }
`;

const LoaderContainer = styled.div`
  height: 60px;
  width: 100%;
  position: relative;
`;

const defaultOptions = {
  allowedTags: ["b", "i", "em", "strong", "a"],
  allowedAttributes: {
    a: ["href"],
  },
};

const sanitize = (dirty, options = defaultOptions) => ({
  __html: sanitizeHtml(dirty, options),
});

const SanitizeHTML = ({ html, options }) => (
  <p dangerouslySetInnerHTML={sanitize(html, options)} />
);

class ProjectCore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coverLoading: true,
      coverPc: "",
      coverMobile: "",
      startAnimationFunc: "",
      startAnimation: false,
      animationIsReady: false,
      imgs: this.props.details.content
        .filter((e) => e.type === "image")
        .map((e) => {
          return {
            image: e.priorityImg ? { url: e.priorityImg } : e.img,
            loaded: false,
          };
        }),
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.ready) {
      this.setState({
        animationIsReady: true,
        startAnimationFunc: setTimeout(
          () => {
            if (window.location.pathname !== "/" && !this.state.coverLoading)
              this.setState({ startAnimation: true });
          },
          nextProps.justLoaded && nextProps.flyingAnimation ? 2500 : 1000
        ),
      });
    }
  }
  componentDidMount() {
    let { imgs } = this.state;
    if (this.props.details.type === "web") {
      let assets = [
        {
          type: "coverMobile",
          file: this.props.details.mobile_thumb.url,
        },
        { type: "coverPc", file: this.props.details.pc_thumb.url },
      ];
      let promises = [];

      assets.forEach((e) => {
        promises.push(
          new Promise((resolve) => {
            fetch(e).then((response) => {
              let im = new Image();
              im.src = e.file;
              im.onload = () => {
                this.setState({ [e.type]: e.file });
                resolve(e);
              };
            });
          })
        );
      });
      Promise.all(promises).then((els) => {
        if (this.state.animationIsReady) {
          this.setState({
            startAnimationFunc: setTimeout(() => {
              this.setState({ startAnimation: true });
            }, 500),
            coverLoading: false,
          });
        } else {
          this.setState({
            coverLoading: false,
          });
        }
      });
    } else {
      this.setState({
        coverLoading: false,
      });
    }

    imgs.forEach((e, i) => {
      new Promise((resolve) => {
        let im = new Image();
        im.src = e.image.url;
        im.onload = () => {
          let newState = Object.assign({}, this.state);
          newState.imgs[i].loaded = true;
          this.setState(newState);
          resolve(e);
        };
        //})
      });
    });
  }
  componentWillUnmount() {
    clearTimeout(this.state.startAnimationFunc);
  }
  render() {
    let { coverLoading, coverPc, coverMobile, startAnimation, imgs } =
      this.state;
    let { details, isMobile } = this.props;
    return (
      <div>
        {details.type === "web" ? (
          coverLoading ? (
            <LoaderContainer>
              <Loader thecolor={details.color}>
                <div />
                <div />
                <div />
                <div />
              </Loader>
            </LoaderContainer>
          ) : (
            <ImageContainer thecolor={details.color}>
              <PcLook
                thecolor={details.color}
                className={`${startAnimation ? "ready" : ""}`}
              >
                <img alt={this.props.details.title} src={coverPc} />
              </PcLook>
              <MobileLook
                thecolor={details.color}
                className={`${startAnimation ? "ready" : ""}`}
              >
                <img alt={this.props.details.title} src={coverMobile} />
              </MobileLook>
            </ImageContainer>
          )
        ) : details.provider === "vimeo" ? (
          <ImageContainer thecolor={details.color}>
            <div className={`video_container ${startAnimation ? "ready" : ""}`}>
              <div className="iframe_wrapper">
                <iframe
                  title={details.title}
                  className={`${startAnimation ? "ready" : ""}`}
                  src={details.video_url}
                  width="800"
                  height="450"
                  frameBorder="0"
                  webkitallowfullscreen={"true"}
                  mozallowfullscreen={"true"}
                  allowFullScreen={true}
                />
              </div>
            </div>
          </ImageContainer>
        ) : (
          <div />
        )}
        <TextField className={`${startAnimation ? "visible" : ""}`}>
          {details.url && (
            <p className="project_url_p">
              <strong>{"Project URL:"}</strong>
              <a className="project_url" href={details.url}>
                {details.url}
              </a>
            </p>
          )}
          {details.content.map((e, i) => {
            if (e.type === "text") {
              return <SanitizeHTML key={i} html={e.content} />;
            } else if (e.type === "text_video") {
              return (
                <div key={i} className="video_section">
                  {!isMobile && (
                    <div className="video">
                      <video
                        autoPlay
                        loop
                        muted
                        preload="true"
                        poster={e.poster.url}
                      >
                        <source src={e.video.url} type="video/mp4" />
                      </video>
                    </div>
                  )}
                  <div className="text">
                    <SanitizeHTML html={e.content} />
                  </div>
                </div>
              );
            } else if (e.type === "image") {
              let the_img;
              if (e.priorityImg) {
                the_img = imgs.filter((b) => b.image.url === e.priorityImg)[0];
              } else {
                the_img = imgs.filter((b) => b.image.url === e.img.url)[0];
              }
              return !the_img.loaded ? (
                <LoaderContainer key={i}>
                  <Loader thecolor={details.color}>
                    <div />
                    <div />
                    <div />
                    <div />
                  </Loader>
                </LoaderContainer>
              ) : (
                <div key={i} className="image_section">
                  {e.title && <h3>{e.title}</h3>}
                  <img
                    alt={this.props.details.title}
                    src={e.priorityImg || e.img.url}
                  />
                </div>
              );
            } else {
              // eslint-disable-next-line
              return;
            }
          })}
          <div className="page_footer">
            <div className="left-side">
              <div
                className="site_link"
                onClick={() => this.props.history.push({ pathname: `/` })}
              >
                {"gauracs"}
                <span style={{ color: "#424242", fontSize: "16px" }}>
                  {".com"}
                </span>
              </div>
            </div>
            <div className="right-side">
              <div
                className="App-logo"
                onClick={() => this.props.history.push({ pathname: `/` })}
              >
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 117 103"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M72.592 99l-.432-9.504c-2.304 4.032-6 7.176-11.088 9.432-5.088 2.256-10.944 3.384-17.568 3.384-6.624 0-12.6-1.416-17.928-4.248-5.328-2.832-9.888-6.624-13.68-11.376S5.2 76.464 3.184 70.272.16 57.672.16 51.048c0-7.008 1.008-13.608 3.024-19.8s4.992-11.592 8.928-16.2C16.048 10.44 20.944 6.816 26.8 4.176c5.856-2.64 12.672-3.96 20.448-3.96 5.664 0 10.968.816 15.912 2.448 4.944 1.632 9.264 3.888 12.96 6.768 3.696 2.88 6.672 6.312 8.928 10.296 2.256 3.984 3.576 8.376 3.96 13.176h-20.16c-.288-2.208-1.056-4.296-2.304-6.264a19.224 19.224 0 0 0-4.752-5.112c-1.92-1.44-4.152-2.592-6.696-3.456a24.242 24.242 0 0 0-7.848-1.296c-8.064 0-14.52 3-19.368 9-4.848 6-7.272 14.712-7.272 26.136 0 3.936.504 7.896 1.512 11.88 1.008 3.984 2.592 7.584 4.752 10.8 2.16 3.216 4.92 5.832 8.28 7.848 3.36 2.016 7.344 3.024 11.952 3.024 3.264 0 6.312-.576 9.144-1.728 2.832-1.152 5.304-2.736 7.416-4.752A22.369 22.369 0 0 0 68.632 72c1.2-2.64 1.8-5.448 1.8-8.424H49.264V47.592h39.888V99h-16.56zm23.616 0V79.56h20.016V99H96.208z"
                    fill={`#63B2FF`}
                    fillRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </div>
        </TextField>
      </div>
    );
  }
}

export default ProjectCore;
