import React, { Component } from "react";
import styled, { createGlobalStyle } from "styled-components";
import boxParams from "./boxParams";
import BackToTop from "./BackToTop";

import ProjectCore from "./projects/ProjectCore";
import { Helmet } from "react-helmet";

const ProjectGlobalStyle = createGlobalStyle`
  .project_page {
    transition: opacity 0.5s;
    opacity:0;
    position:fixed;
    width:100%;
    &.ready {
      opacity:1;
      position:relative;
      width:100%;
    }
  }
  /* .transition_block {
    z-index:20;
    background-color:rgba(99,178,255,1);
    position:fixed;
    display:none;
  } */
     .project_page.fade-exit {
       z-index:900;
       position:relative;
    }
    .project_page.fade-exit-active {
      .inner {
        opacity:0;
        transition: opacity 0.1s 0.5s;
      }
      pointer-events: none;
    }
`;

const ProjectArea = styled.div`
  .inner {
    padding-top: ${(props) =>
      props.titlesize ? `${props.titlesize + 60}px` : `200px`};

    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    .content_area {
      max-width: 940px;
      margin: 0 auto;
      width: 100%;
      padding: 0 40px;
      box-sizing: border-box;
      position: relative;
      @media (max-width: 640px) {
        padding: 0;
      }
    }
    &.leaving {
      /* opacity:0; */
    }
  }
  .blue {
    color: rgba(99, 178, 255, 1);
  }
  /* width:100%;
  min-height:100vh;
  height:auto; */
  .desc_box {
    position: absolute;
    left: ${(props) =>
      props.thewidth ? `calc(50% - ${props.thewidth / 2}px)` : "initial"};
    margin: auto;
    top: 60px;
    text-decoration: none;
    color: rgb(66, 66, 66);
    width: ${(props) => props.boxtitlewidth}px;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    @media (max-width: 600px) {
      box-sizing: border-box;
      width: calc(100% - 80px);
    }
    @media (max-width: 480px) {
      padding-left: 0px;
      padding-right: 0px;
    }
    ${boxParams}
  }
  .inner.leaving {
    .desc_box {
      opacity: 0;
    }
  }
`;

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleWidth: 400,
      mounted: true,
      ready: false,
      readyFunc: "",
      titlesize: undefined,
      willUnmount: false,
      headerFixed: false,
    };
    this.handleResize = this.handleResize.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    //console.log(this.props.history.location.state);
    let titleWidth =
      typeof this.props.history.location.state !== "undefined"
        ? this.props.history.location.state.width
        : undefined;
    let titlesize =
      typeof this.props.history.location.state !== "undefined"
        ? this.props.history.location.state.titlesize
        : this.refs.project_title
        ? this.refs.project_title.getBoundingClientRect().height
        : undefined;
    this.setState({
      titleWidth: titleWidth,
      titlesize: titlesize,
      readyFunc: setTimeout(() => {
        //if (typeof this.props.history.location.state === 'undefined') {
        this.setState({
          ready: true,
        });
        this.handleResize();
        window.scrollTo(0, 0);
      }, 500),
    });
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
  }
  handleResize() {
    this.refs.project_title &&
      this.setState({
        titleWidth: this.refs.project_title.getBoundingClientRect().width,
        titlesize: this.refs.project_title.getBoundingClientRect().height,
      });
  }
  handleScroll(e) {
    let theHeight = 300;
    if (window.pageYOffset > theHeight && !this.state.headerFixed) {
      this.setState({ headerFixed: true });
    } else if (window.pageYOffset <= theHeight && this.state.headerFixed) {
      this.setState({ headerFixed: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
    this.setState({ willUnmount: true });
    clearTimeout(this.state.readyFunc);
    document.querySelector(".Home").classList.add("ready");
    //console.log('project unmounted');
  }
  render() {
    const projectTitle = this.props.projectTitle;
    let { subtitle, tags } = this.props.details;
    let { ready, titlesize, titleWidth, willUnmount, headerFixed } = this.state;
    const hTitle = this.props.new_web_data.generalDataInfos[0].head_title;
    return (
      <React.Fragment>
        <ProjectGlobalStyle />

        <ProjectArea
          titlesize={titlesize}
          boxtitlewidth={this.props.boxtitlewidth}
          thewidth={titleWidth}
          id="project_page"
          className={"project_page " + (ready ? "ready" : "not_ready")}
        >
          <Helmet
            title={`${hTitle} Project: ${projectTitle} - ${subtitle}`}
            meta={[
              {
                name: "description",
                content: this.props.details.content[0].content,
              },
              { property: "og:type", content: "article" },
              {
                property: "og:title",
                content: `${hTitle} Project: ${projectTitle} - ${subtitle}`,
              },
              {
                property: "og:url",
                content: `https://gauracs.com/${this.props.locationData.pathname}`,
              },
            ]}
          />
          {!willUnmount && (
            <div className={"inner"}>
              <div className="back_button_wrapper">
                <div className="centered-container">
                  <div
                    className="link link--arrowed"
                    onClick={() => this.props.history.push({ pathname: `/` })}
                  >
                    <svg
                      className="arrow-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="45"
                      height="45"
                      viewBox="0 0 32 32"
                    >
                      <g
                        fill="none"
                        stroke="#63b1ff"
                        strokeWidth="2"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                      >
                        <circle
                          className="arrow-icon--circle"
                          cx="16"
                          cy="16"
                          r="15.12"
                        ></circle>
                        <path
                          className="arrow-icon--arrow"
                          d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"
                        ></path>
                      </g>
                    </svg>
                    <span className="back">
                      {"Back"}
                      <span className="question_mark">{"?"}</span>
                    </span>
                  </div>
                </div>
              </div>
              <BackToTop visible={headerFixed} />
              <div ref={"project_title"} className="desc_box">
                <div className="title">
                  {projectTitle}
                  <span className="blue">.</span>
                </div>
                <div className="subtitle">{subtitle}</div>
                <div className="tags">{tags}</div>
              </div>
              <div className="content_area">
                <ProjectCore
                  ready={ready}
                  isMobile={this.props.isMobile}
                  history={this.props.history}
                  details={this.props.details}
                  justLoaded={this.props.justLoaded}
                  flyingAnimation={this.props.flyingAnimation}
                  locationData={this.props.locationData}
                />
              </div>
            </div>
          )}
        </ProjectArea>
      </React.Fragment>
    );
  }
}

export default Project;
